import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/shared/material.module';
import { Sanitizer } from '@app/shared/pipes/safe-res-uri.pipe';
import { ButtonBarInfoComponent } from './button-bar-info/button-bar-info.component';
import { DebugInfoComponent } from './debug-info/debug-info.component';
import { FooterInfosComponent } from './footer-infos/footer-infos.component';
import { HeaderComponent } from './header/header.component';
import { SplashscreenComponent } from './splashscreen/splashscreen.component';

@NgModule({
  imports: [CommonModule,
    MaterialModule],
  declarations: [
    HeaderComponent,
    SplashscreenComponent,
    FooterInfosComponent,
    ButtonBarInfoComponent,
    DebugInfoComponent,
    Sanitizer
  ],
  entryComponents: [
    FooterInfosComponent,
    ButtonBarInfoComponent,
    DebugInfoComponent,
  ],
  exports: [
    HeaderComponent,
    SplashscreenComponent,
    Sanitizer
  ]
})
export class LayoutModule {}
