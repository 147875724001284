<h1 mat-dialog-title>Debug-Info</h1>
<div class="mat-body" mat-dialog-content>
  <h3>History</h3>
  <p>
    <code>
      <pre>{{ historyString }}</pre>
    </code>
  </p>
  <h3>Screen</h3>
  <p>
    <code>
      <pre>{{ screenString }}</pre>
    </code>
  </p>
  <h3>ViewModel</h3>
  <p>
    <code>
      <pre>{{ viewModelString }}</pre>
    </code>
  </p>
  <h3>ProjectInfo</h3>
  <p>
    <code>
      <pre>{{ projectInfoString }}</pre>
    </code>
  </p>
  <h3>ConsultancyData</h3>
  <p>
    <code>
      <pre>{{ consultancyDataString }}</pre>
    </code>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Schließen</button>
</div>
