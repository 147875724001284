import { HslColor } from '../models/color-models';
import { addCase, switchCase } from './functional-switch';
import { materialpalette } from './material-palette';

/**
 * Conversts the given Hex string to HSL
 * (Source: https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf)
 *
 * @export
 */
export function hexToHsl(hex: string): HslColor {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || [];

  let red = parseInt(result[1], 16);
  let green = parseInt(result[2], 16);
  let blue = parseInt(result[3], 16);

  red /= 255;
  green /= 255;
  blue /= 255;

  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);

  const lightness = (max + min) / 2;
  let hue = 0;
  let saturation = 0;

  if (max === min) {
    hue = saturation = 0; // achromatic
  } else {
    const delta = max - min;
    saturation =
      lightness > 0.5 ? delta / (2 - max - min) : delta / (max + min);

    const cases = {} as Record<number, number>;
    addCase(cases, {
      if: red,
      then: (green - blue) / delta + (green < blue ? 6 : 0),
    });
    addCase(cases, { if: green, then: (blue - red) / delta + 2 });
    addCase(cases, { if: blue, then: (red - green) / delta + 4 });

    hue = switchCase({ cases, otherwise: () => hue }).on(max) as number;

    hue /= 6;
  }

  return { h: hue, s: saturation, l: lightness };
}

export function toMaterialPalette(hexColor: string) {
  const hslColor = hexToHsl(hexColor);

  return materialpalette(hslColor);
}
