import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@app/layouts/layouts.module';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { CommonFooterComponent } from './components/common-footer/common-footer.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ReenterKeyDialogComponent } from './components/reenter-key-dialog/reenter-key-dialog.component';
import { ErrorPageComponent } from './components/errorPage/error-page.component';
import { IncompatibleBrowserComponent } from './components/incompatible-browser/incompatible-browser.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ContactComponent } from './components/login-page/infos/contact/contact.component';
import { PrivacyComponent } from './components/login-page/infos/privacy/privacy.component';
import { SystemRequirementsComponent } from './components/login-page/infos/system-requirements/system-requirements.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LogoutPageComponent } from './components/logout-page/logout-page.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { NgxMasonryModule } from 'ngx-masonry';
// import { library } from '@fortawesome/fontawesome-svg-core';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MaterialModule } from './material.module';
import { TokenInterceptor } from './services/token-interceptor';
// import { RoutingModule } from './routes/content-layout.routes';
import { VendorComponentsModule } from './vendor-components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    VendorComponentsModule,
    LayoutModule,
  ],
  declarations: [
    SpinnerComponent,
    LoadingSpinnerComponent,
    ErrorPageComponent,
    LoginPageComponent,
    LogoutPageComponent,
    SystemRequirementsComponent,
    PrivacyComponent,
    ContactComponent,
    CommonFooterComponent,
    IncompatibleBrowserComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ReenterKeyDialogComponent
  ],
  entryComponents: [
    SystemRequirementsComponent,
    PrivacyComponent,
    ContactComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ReenterKeyDialogComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LayoutModule,
    MaterialModule,
    VendorComponentsModule,
    ErrorPageComponent,
    LoadingSpinnerComponent,
    SpinnerComponent,
    LoginPageComponent,
    LogoutPageComponent,
    CommonFooterComponent,
    IncompatibleBrowserComponent,
    CommonFooterComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
