import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lens } from 'lens.ts';
import { ApplicationInsightsService } from '../services/application-insights.service';
import { BaseDataService } from '../services/base-data-service';
import { MessagingService } from '../services/messaging.service';
import { DialogService } from './../services/dialog.service';
import { Project } from './models/data/project.model';
import { SessionQuery } from './session.query';
import { SessionServiceCommon } from './session.service common';
import { SessionServiceAkita } from './session.service.akita';
import { SessionStore } from './session.store';

const lProject = lens<Project>();

@Injectable({
  providedIn: 'root',
})
export class SessionService extends BaseDataService {
  private akitaService: SessionServiceAkita;
  private commonService: SessionServiceCommon;
  constructor(
    sessionStore: SessionStore,
    query: SessionQuery,
    http: HttpClient,
    dialogService: DialogService,
    appInsightsService: ApplicationInsightsService,
    messagingService: MessagingService
  ) {
    super(messagingService);
    this.akitaService = new SessionServiceAkita(
      sessionStore,
      query,
      http,
      dialogService,
      appInsightsService,
      messagingService
    );
    this.commonService = new SessionServiceCommon(
      sessionStore,
      messagingService
    );
  }

  get loadProjectData$() {
    return this.akitaService.loadProjectData$;
  }

  get saveConsultancy$() {
    return this.akitaService.saveConsultancy$;
  }

  get updateConsultancyData() {
    return this.akitaService.updateConsultancyData;
  }

  get updateUserId() {
    return this.akitaService.updateUserId;
  }

  get tryResumeConsultancy$() {
    return this.akitaService.tryResumeConsultancy$;
  }

  get updateThemeInfo() {
    return this.akitaService.updateThemeInfo;
  }

  get historyPush() {
    return this.akitaService.historyPush;
  }

  get historyPeek() {
    return this.akitaService.historyPeek;
  }

  get historyView() {
    return this.akitaService.historyView;
  }

  get historyPop() {
    return this.akitaService.historyPop;
  }

  get historyClear() {
    return this.akitaService.historyClear;
  }

  get updateUrlParameters() {
    return this.akitaService.updateUrlParameters;
  }

  get loadScriptFromCDN$() {
    return this.commonService.loadScriptFromCDN$;
  }

  get loadStoryScript() {
    return this.commonService.loadStoryScript;
  }

  get loadStoryStyles() {
    return this.commonService.loadStoryStyles;
  }

  get loadLibsFromCdn$() {
    return this.commonService.loadLibsFromCdn$;
  }

  get loadCSSFromCDN$() {
    return this.commonService.loadCSSFromCDN$;
  }
}
