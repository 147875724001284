// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const version = require('../../package.json').version;

export const environment = {
  isIE: false,
  production: false,
  shouldGreet: true,
  baseUri: '/api/',
  cdnBaseUri: 'https://dwerkvideoviz.azureedge.net/assets/',
  projectCdnBaseUri: 'https://dwerkvideoviz.azureedge.net/assets/',
  applicationInsightsKey: 'b97ebfef-a969-4a4b-b8cc-78cc2400858e',
  hasExtendedButtonBar: false,
  version,
};

/**
 * Prod-CDN: https://dwerkvideoviz.azureedge.net/assets/
 * Dev-CDN: https://dexpertcdn.azureedge.net/assets/
 */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
