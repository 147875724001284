<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<div mat-dialog-content>{{ data.content }}</div>
<div mat-dialog-actions>
  <button
    (click)="onButtonClick()"
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    {{ data.buttonLabel || 'Ok' }}
  </button>
</div>
