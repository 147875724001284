let scaleFont: () => void | undefined;
const resizeTimeInterval = 10;

export function scaleScreenFont(
  areMultiChecksRequested: boolean = false
): void {
  scaleFont = scaleFont || init();

  if (areMultiChecksRequested) {
    scaleFont();
  }
}
function init() {
  const screenDiv = document.getElementById('screen');
  const resizeScreenFont = () => {
    if (screenDiv) {
      resizeFont(screenDiv as HTMLDivElement);
      resizeHtmlFont(document.documentElement, screenDiv);
    }
  };

  const scaleFontFunc = () => {
    resizeScreenFont();
    const timeToken = setInterval(resizeScreenFont, resizeTimeInterval);
    setTimeout(() => {
      clearInterval(timeToken);
    }, 2000);
  };

  window.addEventListener('resize', () => {
    resizeScreenFont();
    const timeToken = setInterval(resizeScreenFont, resizeTimeInterval);
    setTimeout(() => {
      clearInterval(timeToken);
    }, 2000);
  });

  return screenDiv ? scaleFontFunc : undefined;
}

function resizeFont(divElement: HTMLElement) {
  const width = divElement.offsetWidth;
  const fontSize = width / 50;

  divElement.style.fontSize = fontSize + 'px';
}

function resizeHtmlFont(htmlElement: HTMLElement, screenElement: HTMLElement) {
  const width = screenElement.offsetWidth;
  const fontSize = width / 50;
  htmlElement.style.fontSize = 'min(' + fontSize + 'px, calc(0.4em + 1vw))';
}
