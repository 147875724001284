import { Component, Inject, OnInit } from '@angular/core';
import { InfoTexts } from '@app/shared/state/models/data/infoTexts.model';
import { SessionQuery } from '@app/shared/state/session.query';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dwerk-button-bar-info',
  templateUrl: './button-bar-info.component.html',
  styleUrls: ['./button-bar-info.component.css'],
})
export class ButtonBarInfoComponent implements OnInit {
  infos$?: Observable<InfoTexts>;
  isLoading$?: Observable<boolean>;
  testText = `<p>I'm a Test</p>`;
  headers = {
    aboutUs: 'Über uns',
    contact: 'Kontakt',
  } as Record<string, string>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    query: SessionQuery
  ) {
    this.infos$ = query.projectInfoTexts$;
    this.isLoading$ = query.selectLoading();
  }

  ngOnInit() {
    // this.footerInfosService.get().subscribe({
    //   error(err) {
    //     this.error = err;
    //   }
    //  });
  }
}
