export function cleanObject(object: any): any {
  if (!object) {
    return object;
  }

  for (const key of Object.keys(object)) {
    if (/_/.test(key)) {
      delete object[key];
    }
  }

  return object;
}
