<!-- https://material.io/resources/icons/?style=baseline -->
<dwerk-loading-spinner
  *ngIf="!isLoginRequired"
  [delayMs]="400"
></dwerk-loading-spinner>
<div class="login-wrapper" *ngIf="isLoginRequired">
  <mat-card class="login-card">
    <form
      [formGroup]="loginForm"
      class="login-form"
      (ngSubmit)="onSubmit$(loginForm.value)"
    >
      <mat-card-header>
        <mat-card-title style="margin: 0;">Anmeldung</mat-card-title>
        <mat-spinner diameter="25" *ngIf="isExecutingLogin"></mat-spinner>
      </mat-card-header>

      <mat-card-content>
        <mat-form-field class="full-width">
          <mat-label for="projectShortName" id="projectShortNameLabel"
            >Firmenkennung</mat-label
          >
          <input
            matInput
            required
            id="projectShortName"
            type="text"
            formControlName="projectShortName"
            [attr.disabled]="isExecutingLogin ? true : null"
            aria-labelledby="projectShortNameLabel"
          />
          <mat-icon matSuffix>input</mat-icon>
          <mat-error
            *ngIf="
              !isExecutingLogin &&
              projectShortName &&
              projectShortName.dirty &&
              projectShortName.hasError('required')
            "
            >Firmenkennungist erforderlich.
          </mat-error>
          <mat-error
            *ngIf="
              !isExecutingLogin &&
              projectShortName &&
              projectShortName.dirty &&
              projectShortName.hasError('minlength')
            "
            >Firmenkennung besteht aus 4 Zeichen.
          </mat-error>
          <mat-error
            *ngIf="
              !isExecutingLogin &&
              projectShortName &&
              projectShortName.dirty &&
              projectShortName.hasError('maxlength')
            "
            >Firmenkennung besteht aus 4 Zeichen.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label for="userName" id="userNameLabel">Benutzer</mat-label>
          <input
            matInput
            required
            id="userName"
            type="text"
            formControlName="userName"
            aria-labelledby="userNameLabel"
          />
          <mat-icon matSuffix>perm_identity</mat-icon>
          <mat-error
            *ngIf="
              !isExecutingLogin &&
              userName.dirty &&
              userName.hasError('required')
            "
            >Benutzername ist erforderlich</mat-error
          >
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label for="password" id="passwordLabel">Passwort</mat-label>
          <input
            matInput
            required
            id="password"
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            aria-labelledby="passwordLabel"
            [attr.disabled]="isExecutingLogin ? true : null"
          />
          <button
            mat-icon-button
            type="button"
            matSuffix
            (click)="hidePassword = !hidePassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="!hidePassword"
          >
            <mat-icon>{{
              hidePassword ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </button>
          <mat-error
            *ngIf="
              !isExecutingLogin &&
              password.dirty &&
              password.hasError('required')
            "
            >Passwort ist erforderlich</mat-error
          >
        </mat-form-field>

        <mat-error *ngIf="!isExecutingLogin">{{ errorMsg }}</mat-error>
      </mat-card-content>

      <mat-card-footer>
        <button
          mat-stroked-button
          color="primary"
          type="submit"
          [attr.disabled]="
            isExecutingLogin || !loginForm.dirty || !loginForm.valid
              ? true
              : null
          "
        >
          <span>Login </span>
        </button>
      </mat-card-footer>
    </form>
  </mat-card>
</div>
<dwerk-common-footer></dwerk-common-footer>
