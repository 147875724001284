import { ErrorType } from '../constants/error-types';
import {
  ConsultancyData,
  ConsultancyStatus,
} from '../state/models/data/consultancy.model';

export enum Message {
  SpinnerMessage = 'SpinnerMessage',
  FullscreenMessage = 'FullscreenMessage',
  HasErrorMessage = 'HasErrorMessage',
  RaiseErrorMessage = 'RaiseErrorMessage',
  AgentMessage = 'AgentMessage',
  ConsultancyLoaded = 'ConsultancyLoadedMessage',
  EmployeeDataLoaded = 'EmployeeDataLoadedMessage',
  StoryboardStartMessage = 'StoryboardStartMessage',
  ProgressBarProceedMessage = 'ProgressBarProceedMessage',
  ProjectMessage = 'ProjectMessage',
  GetPortalAction = 'GetPortalAction',
  VideoInitialized = 'VideoInitialized',
  VideoPlayStateChanged = 'VideoPlayStateChangedMessage',
  EcxecuteVideoCommmand = 'EcxecuteVideoCommmanddMessage',
  VideoSoundChange = 'VideoSoundChange',
}

export interface RaiseErrorMessagePayLoad {
  errorType: ErrorType;
  message?: string;
}
export interface AgentMessagePayLoad {
  agentId: string;
}
export interface ConsultancyLoadedPayLoad {
  status: ConsultancyStatus;
  consultancyData: ConsultancyData;
  consultancyId: string;
  userId?: string;
  lastScreen?: string;
  language?: string;
  shouldReload?: boolean;
}
export interface EmployeeDataLoadedPayload {
  userId: string;
  decryptionKeyHash?: string;
}
export interface ProjectMessagePayLoad {
  id: string;
}
export interface SpinnerMessagePayLoad {
  isLoading: boolean;
}

export interface FullscreenMessagePayload {
  isFullscreen: boolean;
}

export interface StoryboardStartMessagePayload {
  playVideo: () => void;
}

export interface ProgressBarMessagePayload {
  nextPhase?: string;
  shouldFinish?: boolean;
}

export interface GetPortalActionPayload {
  actionName: string;
  action?: (args: any) => any;
  action$?: (args: any) => Promise<any>;
}

export interface VideoPlayStateChangedPayload {
  hasReachedEnd: boolean;
  hasStarted: boolean;
  isPlaying: boolean;
}
export enum VideoCommand {
  play = 'play',
  pause = 'pause',
  fullscreenOn = 'fullscreenOn',
  fullscreenOff = 'fullscreenOff',
  soundOn = 'soundOn',
  soundOff = 'soundOff',
}
