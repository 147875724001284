import { toPromise } from '@app/core/functions/promise-functions';
import { urlJoin } from '@app/core/functions/string-functions';
import { environment } from 'src/environments/environment';
import { Log } from '../constants/app-constants';
import { ErrorType } from '../constants/error-types';
import { BaseDataService } from '../services/base-data-service';
import { MessagingService } from '../services/messaging.service';
import {
  CssLibrary,
  JsLibrary,
  StoryLibs,
} from './models/screens/storyBoard.model';
import { SessionStore } from './session.store';

let self: SessionServiceCommon;

export class SessionServiceCommon extends BaseDataService {
  constructor(
    private sessionStore: SessionStore,
    messagingService: MessagingService
  ) {
    super(messagingService);
    self = this;
  }
  loadScriptFromCDN$(scriptUrl: string): Promise<boolean> {
    return toPromise((resolve, reject) => {
      self.sessionStore.setLoading(true);
      self.appendScript(
        urlJoin(environment.projectCdnBaseUri, scriptUrl),
        () => {
          resolve(true);
          Log.debug('story board script loaded.');
          self.sessionStore.setLoading(false);
        },
        (error: any) => {
          self.raiseError(
            ErrorType.NotFound,
            `Javascript file "${scriptUrl}" not found`
          );

          reject(error);
        }
      );
    });
  }
  loadStoryScript(scriptContent: string): boolean {
    self.appendStoryScript(scriptContent);
    return true;
  }
  
  loadStoryStyles(stylesContent: string): boolean {
    self.appendStoryStyles(stylesContent);
    return true;
  }

  loadCSSFromCDN$(cssFileName: string): Promise<boolean> {
    if (document.getElementById(cssFileName)) {
      return Promise.resolve(true);
    }
    return toPromise((resolve, reject) => {
      self.sessionStore.setLoading(true);
      try {
        const cssFullPath = urlJoin(environment.projectCdnBaseUri, cssFileName);

        const head = document.head;
        const link = document.createElement('link');
        link.id = cssFileName;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = cssFullPath;
        link.media = 'all';
        head.appendChild(link);

        link.onload = () => resolve(true);
        link.onerror = () => resolve(false);
      } finally {
        self.sessionStore.setLoading(true);
      }
    });
  }

  private appendScript(
    scriptUrl: string,
    successCallback: () => void,
    errorCallback: (err: any) => void,
    isModule = false,
    defer = false
  ) {
    const head = document.head;
    const script = document.createElement('script');

    //script.src = urlJoin(environment.projectCdnBaseUri, scriptUrl);
    script.src = scriptUrl;
    script.type = 'text/javascript';
    script.defer = defer;
    head.appendChild(script);

    script.onload = successCallback;
    script.onerror = errorCallback;
  }

  private appendStoryScript(scriptContent: string) {
    const head = document.head;
    const script = document.createElement('script');

    script.textContent = scriptContent;
    script.type = 'module'; //'text/javascript';
    head.appendChild(script);
  }

  private appendStoryStyles(stylesContent: string) {
    const head = document.head;
    const style = document.createElement('style');

    style.type = 'text/css';
    style.appendChild(document.createTextNode(stylesContent));
    head.appendChild(style);
  }

  async loadLibsFromCdn$(libs: StoryLibs) {
    //(libs: { js: JsLibrary[], css: CssLibrary[] }) {
    if (!libs) return;

    let jslibs = libs.js || [];
    // [
    //   {
    //     uri: 'https://dwerkvideoviz.azureedge.net/assets/_dev/lib/js/dayjs.min.js',
    //     module: false,
    //   },
    // ];
    let csslibs = libs.css || [];
    // [
    //   {
    //     uri: 'https://dwerkvideoviz.azureedge.net/assets/_dev/lib/css/teststyle.css',
    //   },
    // ];
    self.sessionStore.setLoading(true);

    await Promise.all(
      jslibs.map(async (lib) => {
        if (lib && lib.url) {
          await self.appendJsLib(lib.url, lib.module);
        }
      })
    );

    await Promise.all(
      csslibs.map(async (lib) => {
        if (lib && lib.url) {
          await self.appendCssLib(lib.url);
        }
      })
    );
    self.sessionStore.setLoading(false);
  }

  private async appendJsLib(scriptUrl: string, isModule = false) {
    return toPromise((resolve, reject) => {
      const head = document.head;
      const script = document.createElement('script');

      //script.src = urlJoin(environment.projectCdnBaseUri, scriptUrl);
      script.src = scriptUrl;
      script.type = isModule ? 'module' : 'text/javascript';
      head.appendChild(script);
      script.onload = () => {
        Log.debug(`library "${scriptUrl}" loaded.`);
        resolve(true);
      };
      script.onerror = (error: any) => {
        self.raiseError(
          ErrorType.NotFound,
          `Javascript file "${scriptUrl}" not found`
        );

        reject(error);
      };
    });
  }

  private async appendCssLib(styleUrl: string) {
    return toPromise((resolve, reject) => {
      const head = document.head;
      const link = document.createElement('link');
      //link.id = cssFileName;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = styleUrl;
      link.media = 'all';
      head.appendChild(link);
      link.onload = () => {
        Log.debug(`library "${styleUrl}" loaded.`);
        resolve(true);
      };
      link.onerror = (error: any) => {
        self.raiseError(ErrorType.NotFound, `CSS file "${styleUrl}" not found`);

        reject(error);
      };
    });
  }
}
