import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params } from '@angular/router';
import { cleanObject } from '@app/core/functions/object-functions';
import { urlJoin } from '@app/core/functions/string-functions';
import { is } from '@app/core/functions/type-guards';
import { Project } from '@app/shared/state/models/data/project.model';
import { StoryBoard } from '@app/shared/state/models/screens/storyBoard.model';
import { lens } from 'lens.ts';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorType } from '../constants/error-types';
import { Events } from '../models/events';
import {
  AgentMessagePayLoad,
  ConsultancyLoadedPayLoad,
  Message,
} from '../models/messages';
import { ThemeInfoTexts } from '../models/theme.model';
import { ApplicationInsightsService } from '../services/application-insights.service';
import { BaseDataService } from '../services/base-data-service';
import { MessagingService } from '../services/messaging.service';
import { DialogService } from './../services/dialog.service';
import { projectTypesWithAgents } from './models/constants';
import { Agent } from './models/data/agent.model';
import {
  Consultancy,
  ConsultancyData,
  ConsultancyStatus,
  ContinueStatus,
} from './models/data/consultancy.model';
import { InsuranceCompany } from './models/data/insuranceCompany.model';
import { SequenceInternal } from './models/screens/story.model';
import { SessionQuery } from './session.query';
import { SessionStore, updateStore } from './session.store';

const apiUri = environment.baseUri;
const lProject = lens<Project>();
const lProjectType = lProject.projectInfo && lProject.projectInfo.type.get();
const lProjectAgentList =
  lProject.projectInfo && lProject.projectInfo.agentIdentifiers.get();
let self: SessionServiceAkita;

export class SessionServiceAkita extends BaseDataService {
  private project?: Project;
  constructor(
    private sessionStore: SessionStore,
    private query: SessionQuery,
    private http: HttpClient,
    private dialogService: DialogService,
    private appInsightsService: ApplicationInsightsService,
    messagingService: MessagingService
  ) {
    super(messagingService);
    self = this;
  }

  async loadProjectData$(
    url: string,
    id1: string,
    id2: string,
    userId?: string
  ) {
    self.appInsightsService.startLogEvent(Events.loadData);
    self.sessionStore.setLoading(true);
    let agentIdentifier = '';
    let projectIdentifier = '';
    try {
      this.project = await self
        .loadProject$(url, id1, id2)
        .toPromise<Project | undefined>();

      const project = this.project;
      projectIdentifier =
        (project && project.projectInfo.projectIdentifier) || '';
      //debugger;
      if (userId && project) {
        await self.processConsultancy$(userId, project);
      }

      if (project && project.agent) {
        agentIdentifier = project.agent.agentIdentifier;
        self.messagingService.broadcast<AgentMessagePayLoad>(
          Message.AgentMessage,
          { agentId: agentIdentifier }
        );
      }

      self.checkAgentId(project as Project, agentIdentifier);
    } finally {
      self.sessionStore.setLoading(false);
      self.appInsightsService.endLogEvent(Events.loadData, {
        id: projectIdentifier,
        agentId: agentIdentifier || '',
      });
    }
  }

  public async tryResumeConsultancy$(userId: string | undefined) {
    const project = this.project;
    if (!(userId && project)) return false;
    let projectIdentifier =
      (project && project.projectInfo.projectIdentifier) || '';
    return self.processConsultancy$(userId, project, true);
  }

  private async processConsultancy$(
    userId: string,
    project: Project,
    shouldReload: boolean = false
  ) {
    let status = await this.checkConsultancy$(userId, project.projectInfo.projectIdentifier, "").toPromise<ContinueStatus | undefined>();//consultancy.status;
    console.log("Status", status);
    if (
      !is<ContinueStatus>(status) ||
      status == undefined ||
      status == ContinueStatus.NoConsultancy
    ) {
      return false;
    }

    if (status == ContinueStatus.Closed) {
      await this.dialogService.openAlert$({
        content:
          'Sie haben die Beratung bereits abgeschlossen. ' +
          'Eine erneute Beratung ist leider nicht möglich.',
      });
      window.location.reload();
      return true;
    }

    if (project.projectInfo?.masterData?.isWelcomeBackAllowed) {
      const shouldContinue = !(await this.dialogService.openConfirmation$({
        title: 'Willkommen zurück!',
        content:
          'Wollen Sie die Beratung dort fortsetzen, wo Sie sie zuletzt unterbrochen hatten, oder wieder neu vom Anfang starten?',
        yesLabel: 'Beratung neu starten',
        noLabel: 'Beratung fortsetzen',
      }));

      if (shouldContinue) {
        let key = "";
        if (status == ContinueStatus.RequiresKey) {
          let tries = 0;
          while (status != ContinueStatus.Ok) {
            key = (await this.dialogService.openKeyReentry$({
              key: key,
              showError: tries > 0
            })) || key;
            tries++;
            status = await this.checkConsultancy$(userId, project.projectInfo?.projectIdentifier, key).toPromise<ContinueStatus | undefined>();
            if (tries > 2) {
              await this.dialogService.openAlert$({
                content:
                  'Sie haben zu oft einen falschen Freischaltcode eingegeben. ' +
                  'Eine Fortsetzung der Beratung ist leider nicht möglich.',
              });
              window.location.reload();
              return true;
            }
          }
        }
        if (status == ContinueStatus.Ok) {
          const consultancy = await self
            .loadConsultancy$(userId, project.projectInfo?.projectIdentifier, key)
            .toPromise<Consultancy | undefined>();

          if (
            !is<Consultancy>(consultancy) ||
            !consultancy ||
            !consultancy.status ||
            !consultancy.consultancyData
          ) {
            return false;
          }

          updateStore(self.sessionStore, (state) => {
            state.consultancyData = cleanObject(consultancy.consultancyData);
          });

          self.messagingService.broadcast<ConsultancyLoadedPayLoad>(
            Message.ConsultancyLoaded,
            {
              status: consultancy.status,
              consultancyData: clone(consultancy.consultancyData),
              consultancyId: consultancy.id as string,
              lastScreen: consultancy.lastScreen,
              language: consultancy.language,
              userId,
              shouldReload
            }
          );
          return true;
        }
      } else {
        return false;
      }
    }
  }

  private loadProject$(
    url: string,
    id1: string,
    id2: string
  ): Observable<Project | undefined> {
    const backendUrl = urlJoin(
      apiUri,
      'Project',
      id1,
      id2,
      '?url=' + encodeURIComponent(url)
    );

    return self.http.get<Project>(backendUrl).pipe(
      tap((project) => {
        if (project && project.paths) {
          const cdnFolder = project.paths.cdnRoot;

          environment.projectCdnBaseUri = urlJoin(
            environment.cdnBaseUri,
            cdnFolder || ''
          );

          project.projectInfo.agent = project.agent;
          setAgentInsuranceNumber(
            project.agent,
            project.projectInfo.insuranceCompany
          );

          setRootPoster(project.storyboard);

          updateStore(self.sessionStore, (state) => {
            state.project = cleanObject(project);
            state.agent = cleanObject(project.agent);
          });
        } else {
          self.raiseError(ErrorType.InvalidProject);
        }
      }),
      catchError((err) =>
        self.handleError(err, `while loading project "${id1}"`)
      )
    );

    function setAgentInsuranceNumber(
      agent: Agent | undefined,
      insuranceCompany: InsuranceCompany | undefined
    ): void {
      if (agent && insuranceCompany) {
        const insuranceInfos = agent.insurance2AgentInfos || [];
        const insuranceInfo = insuranceInfos.find(
          (info) => info.insuranceCompanyId === insuranceCompany.id
        );

        if (insuranceInfo) {
          agent.agentInsuranceNumber = insuranceInfo.agentNumber;
        }
      }
    }

    function setRootPoster(storyboard: StoryBoard) {
      if (storyboard && storyboard.root) {
        const rootId = storyboard.root;
        const rootSequence = storyboard.run[rootId];
        const firstSequence = {
          ...rootSequence,
          poster: storyboard.poster,
        } as SequenceInternal;

        storyboard.run[rootId] = firstSequence;
      }
    }
  }

  private checkConsultancy$(
    userId: string,
    projectId: string,
    passCode: string
  ): Observable<ContinueStatus> {
    const backendUrl = urlJoin(apiUri, 'consultancy', 'check', projectId, userId);

    return self.http
      .post<ContinueStatus>(
        backendUrl,
        JSON.stringify(passCode),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        catchError((err) =>
          self.handleError(
            err,
            `while checking consultancy for user "${userId}"`
          )
        )
      );
  }

  private loadConsultancy$(
    userId: string,
    projectId: string,
    passCode: string = ""
  ): Observable<Consultancy | undefined> {
    const backendUrl = urlJoin(apiUri, 'consultancy', 'get', projectId, userId);

    return self.http
      .post<Consultancy>(
        backendUrl,
        JSON.stringify(passCode),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        catchError((err) =>
          self.handleError(
            err,
            `while loading consultancy for user "${userId}"`
          )
        )
      );

    function setAgentInsuranceNumber(
      agent: Agent | undefined,
      insuranceCompany: InsuranceCompany
    ): void {
      if (agent && insuranceCompany) {
        const insuranceInfos = agent.insurance2AgentInfos || [];
        const insuranceInfo = insuranceInfos.find(
          (info) => info.insuranceCompanyId === insuranceCompany.id
        );

        if (insuranceInfo) {
          agent.agentInsuranceNumber = insuranceInfo.agentNumber;
        }
      }
    }

    function setRootPoster(storyboard: StoryBoard) {
      if (storyboard && storyboard.root) {
        const rootId = storyboard.root;
        const rootSequence = storyboard.run[rootId];
        const firstSequence = {
          ...rootSequence,
          poster: storyboard.poster,
        } as SequenceInternal;

        storyboard.run[rootId] = firstSequence;
      }
    }
  }

  private checkAgentId(project: Project, _agentIdentifier?: string) {
    if (project) {
      const agentIdentifier = _agentIdentifier || '_no value_';
      const projectType = lProjectType(project) || 'error';
      const agentList = lProjectAgentList(project) || [];

      if (
        projectTypesWithAgents.includes(projectType.toLowerCase()) &&
        !agentList.includes(agentIdentifier)
      ) {
        self.raiseError(
          ErrorType.InvalidAgent,
          `Agent with identifier '${agentIdentifier}' is not valid for this project (Id: '${project.id}')`
        );
      }
    }
  }

  saveConsultancy$(consultancy: Consultancy): Observable<any> {
    const url = urlJoin(apiUri, 'Consultancy', consultancy.id.toString());
    return self.http.put<Consultancy>(url, consultancy);
  }

  updateConsultancyData(consultancy: ConsultancyData) {
    consultancy = clone(consultancy);
    updateStore(self.sessionStore, (state) => {
      state.consultancyData = consultancy;
    });
  }

  updateUserId(userId?: string) {
    updateStore(self.sessionStore, (state) => {
      state.userId = userId;
    });
  }

  updateThemeInfo(themeInfo: ThemeInfoTexts) {
    updateStore(self.sessionStore, (state) => {
      state.themeInfoTexts = themeInfo;
    });
  }

  historyPush(newScreenId: string) {
    updateStore(self.sessionStore, (state) => {
      state.screenHistory = [...state.screenHistory, newScreenId];
    });
  }
  historyPop() {
    const history = [...self.query.getValue().screenHistory];
    const lastScreen = history.pop();

    updateStore(self.sessionStore, (state) => {
      state.screenHistory = [...history];
    });

    return lastScreen;
  }

  historyPeek() {
    const history = [...self.query.getValue().screenHistory];

    return history.pop();
  }

  historyView() {
    return [...self.query.getValue().screenHistory];
  }

  historyClear() {
    updateStore(self.sessionStore, (state) => {
      state.screenHistory = [];
    });
  }

  updateUrlParameters(urlParameters: Params) {
    updateStore(self.sessionStore, (state) => {
      state.urlParameters = urlParameters;
    });
  }
}

function clone<T>(val: T): T {
  return JSON.parse(JSON.stringify(val)) as T;
}
