<div class="loading-spinner" *ngIf="showLoading">
  <!-- <svg
    class="spinner"
    width="3em"
    height="3em"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="path"
      fill="none"
      stroke-width="6"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    ></circle>
  </svg> -->
  <div id="inTurnFadingTextG">
    <div id="inTurnFadingTextG_1" class="inTurnFadingTextG">L</div>
    <div id="inTurnFadingTextG_2" class="inTurnFadingTextG">o</div>
    <div id="inTurnFadingTextG_3" class="inTurnFadingTextG">a</div>
    <div id="inTurnFadingTextG_4" class="inTurnFadingTextG">d</div>
    <div id="inTurnFadingTextG_5" class="inTurnFadingTextG">i</div>
    <div id="inTurnFadingTextG_6" class="inTurnFadingTextG">n</div>
    <div id="inTurnFadingTextG_7" class="inTurnFadingTextG">g</div>
    <div id="inTurnFadingTextG_8" class="inTurnFadingTextG">.</div>
    <div id="inTurnFadingTextG_9" class="inTurnFadingTextG">.</div>
    <div id="inTurnFadingTextG_10" class="inTurnFadingTextG">.</div>
  </div>
</div>
