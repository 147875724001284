import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlJoin } from '@app/core/functions/string-functions';
import { ApplicationInsightsService } from '@app/shared/services/application-insights.service';
import { SessionService } from '@app/shared/state/session.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorType } from '../constants/error-types';
import { Events } from '../models/events';
import { BaseDataService } from './base-data-service';
import { MessagingService } from './messaging.service';

const apiUri = environment.baseUri;
const browserStorage = sessionStorage;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseDataService {
  constructor(
    private http: HttpClient,
    private appInsightsService: ApplicationInsightsService,
    private sessionService: SessionService,
    messagingService: MessagingService
  ) {
    super(messagingService);
  }

  public get isAuthorized(): boolean {
    return !!this.token;
  }

  public get token(): string | undefined {
    return browserStorage.getItem('access_token') || undefined;
  }

  public get tokenPayload(): TokenPayload | undefined {
    if (!this.token) {
      return;
    }

    const payload = {
      isValid: () => Math.round(Date.now() / 1000) < payload.exp,
      ...JSON.parse(atob(this.token.split('.')[1] || '{}')),
    } as TokenPayload;

    return payload;
  }

  public async authorizeAnonymously$(
    anonymousAuthData: AnonymousAuthData
  ): Promise<AuthResult> {
    const url = urlJoin(apiUri, 'token/anonymous');

    const authResult$ = this.http
      .post<AuthResult>(url, anonymousAuthData)
      .pipe(catchError((err) => this.handleAuthError(err, `while login`)))
      .toPromise();

    this.appInsightsService.startLogEvent(Events.login);

    const authResult: AuthResult = (await authResult$) || null;

    if (authResult.token) {
      browserStorage.setItem('access_token', authResult.token);
    }

    this.appInsightsService.endLogEvent(Events.login);

    return authResult;
  }

  public async authorize$(authData: AuthData): Promise<AuthResult> {
    const url = urlJoin(apiUri, 'token');

    const authResult$ = this.http
      .post<AuthResult>(url, authData)
      .pipe(catchError((err) => this.handleAuthError(err, `while login`)))
      .toPromise();

    this.appInsightsService.startLogEvent(Events.login);

    const authResult: AuthResult = (await authResult$) || null;

    if (authResult.token) {
      browserStorage.setItem('access_token', authResult.token);
      this.sessionService.updateUserId(
        this.tokenPayload && this.tokenPayload.userId
      );
    }

    this.appInsightsService.endLogEvent(Events.login);

    return authResult;
  }

  public clearToken() {
    browserStorage.removeItem('access_token');
  }

  protected handleAuthError(
    error: any,
    reason: string,
    errorType: ErrorType = ErrorType.Unexpected
  ) {
    return super.handleError(error, reason, errorType);
  }
}

export interface TokenPayload {
  url: string;
  projectShortName: string;
  projectIdentifier: string;
  agentIdentifier: string;
  name: string;
  userId: string;
  exp: number;
  isValid(): boolean;
}

export interface AnonymousAuthData {
  url: string;
  projectId?: string;
}

export interface AuthData extends AnonymousAuthData {
  projectShortName: string;
  userName: string;
  password: string;
}

export enum AuthError {
  ProjectError = 0,
  LoginRequired = 1,
  LoginError = 2,
}

export interface AuthResult {
  projectIdentifier?: string;
  projectShortName?: string;
  agentIdentifiers: string[];
  token?: string;
  error?: AuthError;
}
