export const urlJoin = require('url-join') as (...parts: string[]) => string;

export const urlNormalize = (url: string) =>
  url.endsWith('/') ? url : `${url}/`;

export const urlEquals = (url1: string, url2: string) =>
  urlNormalize(url1) === urlNormalize(url2);

export function camelToKebapCase(pattern: string): string {
  return pattern
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .toLowerCase();
}
