import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, delay as rxjsDelay } from 'rxjs/operators';

interface Message {
  key: string | symbol;
  payload?: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  private messageBus: Subject<Message>;

  constructor() {
    this.messageBus = new Subject<Message>();
  }

  broadcast<T = unknown>(key: string | symbol, payload?: T) {
    this.messageBus.next({ key, payload });
  }

  /**
   * @param [delay=false] If delay is set to true, the handler is invoked with a timeout of 0.
   * This avoids change detection errors (ExpressionChangedAfterItHasBeenCheckedError)
   * when manipulating UI bound properties in the handler, see:
   * https://blog.angular-university.io/angular-debugging/
   */
  on<T>(
    key: string | symbol,
    handler?: (payload: T) => void,
    delay: boolean = false
  ): [Observable<T>, Subscription | undefined] {
    const filterForKey = filter<Message>(message => message.key === key);
    const getPayload = map<Message, T>(message => message.payload as T);
    let subscription: Subscription | undefined;

    const payload$ = this.messageBus.pipe(filterForKey, getPayload);

    if (handler) {
      subscription = delay
        ? payload$.pipe(rxjsDelay(0)).subscribe(handler)
        : payload$.subscribe(handler);
    }

    return [payload$, subscription];
  }
}
