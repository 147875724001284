import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dwerk-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css'],
})
export class AlertDialogComponent implements OnInit {
  data: AlertData;
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _data: any
  ) {
    this.data = _data;
  }

  onButtonClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {}
}

export interface AlertData {
  title?: string;
  content: string;
  buttonLabel?: string;
  width?: string;
}
