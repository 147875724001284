import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlJoin } from '@app/core/functions/string-functions';
import { Events } from '@app/shared/models/events';
import { ApplicationInsightsService } from '@app/shared/services/application-insights.service';
import { BaseDataService } from '@app/shared/services/base-data-service';
import { MessagingService } from '@app/shared/services/messaging.service';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EmployeeDataRequest } from 'src/app/modules/storyboard/models/EmployeeDataRequest'
import { EmployeeDataLoadedPayload, Message } from '@app/shared/models/messages';

const apiUri = environment.baseUri;

@Injectable({
  providedIn: 'root',
})
export class DataService extends BaseDataService {
  constructor(
    private http: HttpClient,
    private appInsightsService: ApplicationInsightsService,
    messagingService: MessagingService
  ) {
    super(messagingService);
  }

  private cache: Record<string, Record<string, unknown>> = {};

  public loadDocumentTemplate$(
    identifier: string
  ): Promise<unknown | undefined> {
    const docTemplateCache = this.cache.documents || {};

    const cachedTemplate = docTemplateCache[identifier];
    if (cachedTemplate) {
      return Promise.resolve(cachedTemplate);
    }

    this.appInsightsService.startLogEvent(Events.loadData);
    const backendUrl = urlJoin(apiUri, 'document', identifier);
    const document$ = this.http
      .get<unknown>(backendUrl)
      .pipe(
        tap((doc) => {
          if (doc) {
            docTemplateCache[identifier] = doc;
          }
        }),
        catchError((err) =>
          this.handleError(
            err,
            `while loading document template "${identifier}"`
          )
        )
      )
      .toPromise();
    this.appInsightsService.endLogEvent(Events.loadData);

    return document$;
  }

  public loadSettings$(): Promise<any> {
    this.appInsightsService.startLogEvent(Events.loadData);
    const backendUrl = urlJoin(apiUri, 'settings');
    const settings$ = this.http
      .get<any>(backendUrl)
      .pipe(
        catchError((err) => this.handleError(err, `while loading settings.`))
      )
      .toPromise();

    return settings$;
  }

  public loadEmployeeData$(request: EmployeeDataRequest | string): Promise<unknown | undefined> {
    let employeeDataRequest : EmployeeDataRequest;
    if(typeof request == "string") {
      employeeDataRequest = { passCode: request } as EmployeeDataRequest;
    } else {
      employeeDataRequest = request as EmployeeDataRequest;
    }

    const employeeDataCache = this.cache.employeeData || {};

    const cachedEmployeeData = employeeDataCache[employeeDataRequest.passCode];
    if (cachedEmployeeData) {
      return Promise.resolve(cachedEmployeeData);
    }

    this.appInsightsService.startLogEvent(Events.loadData);

    // force string payload to be handled as JSON
    // otherwise content-type text/plain is inferred
    // which causes 'Unsupported Media Type' error on backend
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const backendUrl = urlJoin(apiUri, 'employeeData');
    const employeeData$ = this.http
      .post<unknown>(backendUrl, JSON.stringify(employeeDataRequest), httpOptions)
      .pipe(
        tap((employeeData) => {
          if (employeeData) {
            employeeDataCache[employeeDataRequest.passCode] = employeeData;
          }
        }),
        catchError((err) =>
          this.handleError(err, `while loading employeeData.`)
        )
      )
      .toPromise()
      .then((value: any) => {
        if(value && value.userId && value.userId != null) {
          const decryptionKeyHash = value.decryptionKeyHash;
          this.messagingService.broadcast<EmployeeDataLoadedPayload>(
            Message.EmployeeDataLoaded,
            {
              userId: value.userId,
              decryptionKeyHash: decryptionKeyHash
            }
          );
        }
        return value;
      });
    
    this.appInsightsService.endLogEvent(Events.loadData);

    return employeeData$;
  }
}
