<div
  class="container"
  [class.fullscreen]="isFullscreen"
  [class.hide-overflow]="!isPresentationDone"
>
  <dwerk-header
    *ngIf="showHeader"
    class="header"
    [title]="themeInfo && themeInfo.header"
    [underline]="themeInfo && themeInfo.underline"
  >
  </dwerk-header>

  <div *ngIf="!isIE" class="main">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  <div *ngIf="isIE" class="main">
    <dwerk-incompatible-browser></dwerk-incompatible-browser>
  </div>

  <dwerk-splashscreen
    class="splashscreen"
    [class.slide]="isPresentationDone && !(loading$ | async)"
    [class.remove]="shouldRemoveGreeting"
    [isDone]="splashscreenEnded"
    [hidden]="!shouldGreet || !splashscreenStarted"
  >
  </dwerk-splashscreen>
</div>
<div class="version-info">
  <div>
    {{ version }}
  </div>
</div>
<!-- *ngIf="themeInfo.header" -->
<!-- [hidden]="hasError || !themeInfo || !themeInfo.header" -->
