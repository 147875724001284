<div class="infos small">
  <div>
    <a href="javascript:void(0)" (click)="showInfos('systemRequirements')"
      >Systemvoraussetzungen</a
    >
  </div>
  <div>
    <a href="javascript:void(0)" (click)="showInfos('privacyRegulation')"
      >Datenschutz</a
    >
  </div>
  <div>
    <a href="javascript:void(0)" (click)="showInfos('legalNotice')"
      >Impressum</a
    >
  </div>
</div>
