import { HslColor } from '../models/color-models';

// source: https://github.com/leodido/material-palette

/**
 * Minimize the maximum possible loss
 *
 */

const minimax = (val: number) => {
  return Math.min(100, Math.max(0, val));
};

/**
 * Material Palette Generator
 *
 * It calculates all colors from base.
 * These colors were determined by finding all HSL values for each google palette.
 * Then calculating the differences in H, S, and L per color change individually.
 * Finally applying these here.
 *
 */
export const materialpalette = (hsl: HslColor) => {
  let h = hsl.h;
  let s = hsl.s;
  let l = hsl.l;

  h = Math.round(h);
  s = Math.round(s);
  l = Math.round(l);

  if (isNaN(h) || isNaN(s) || isNaN(l)) {
    throw new TypeError('Invalid input');
  }
  if (h < 0 || h > 360) {
    throw new RangeError('Hue must be an integer within [0, 360]; given ' + h);
  }
  if (s < 0 || s > 100) {
    throw new RangeError(
      'Saturation must be an integer within [0, 100]; given ' + s
    );
  }
  if (l < 0 || l > 100) {
    throw new RangeError(
      'Lightness must be an integer within [0, 100]; given ' + l
    );
  }

  return {
    50: { h, s, l: minimax(l + 52) },
    100: { h, s, l: minimax(l + 37) },
    200: { h, s, l: minimax(l + 26) },
    300: { h, s, l: minimax(l + 12) },
    400: { h, s, l: minimax(l + 6) },
    500: { h, s, l },
    600: { h, s, l: minimax(l - 6) },
    700: { h, s, l: minimax(l - 12) },
    800: { h, s, l: minimax(l - 18) },
    900: { h, s, l: minimax(l - 24) },
    A100: { h: h + 5, s, l: minimax(l + 24) }, // { h, s, l: minimax(l + 52) }
    A200: { h: h + 5, s, l: minimax(l + 16) }, // { h, s, l: minimax(l + 37) }
    A400: { h: h + 5, s, l: minimax(l - 1) }, // { h, s, l: minimax(l + 6) }
    A700: { h: h + 5, s, l: minimax(l - 12) }, // { h, s, l: minimax(l - 12) }
  };
};
