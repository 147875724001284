import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VideoComponent } from './modules/storyboard/components/video/video.component';
import { ErrorPageComponent } from './shared/components/errorPage/error-page.component';
import { LoginPageComponent } from './shared/components/login-page/login-page.component';
import { LogoutPageComponent } from './shared/components/logout-page/logout-page.component';
import { ErrorType } from './shared/constants/error-types';
import { AuthGuard } from './shared/services/auth.guard';

const routeConfig: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  {
    path: '',
    redirectTo: 'video',
    pathMatch: 'full',
  },
  {
    path: 'video',
    component: VideoComponent,
    canActivate: [AuthGuard],
  },
  // Fallback
  {
    path: '**',
    component: ErrorPageComponent,
    data: { type: ErrorType.InvalidUrl },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routeConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
