import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'dwerk-incompatible-browser',
  templateUrl: './incompatible-browser.component.html',
  styleUrls: ['./incompatible-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncompatibleBrowserComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    document.documentElement.style.setProperty(
      '--palette-background-dialog',
      '#ffffff'
    );
  }
}
