import { Injectable } from '@angular/core';
import * as pdfMakeStaticNS from 'pdfmake/build/pdfmake';
// tslint:disable-next-line: no-duplicate-imports
import { TCreatedPdf } from 'pdfmake/build/pdfmake';
import {
  Content,
  DynamicContent,
  TDocumentDefinitions,
} from 'pdfmake/interfaces';
import { DataService } from './data.service';

type pdfMakeStatic = typeof pdfMakeStaticNS;

@Injectable({ providedIn: 'root' })
export class CreateDocumentService {
  private pdfMake?: pdfMakeStatic;
  private pdfMake$: Promise<{ default: pdfMakeStatic }>;
  constructor(private dataService: DataService) {
    this.pdfMake$ = import('./pdfMake-import') as unknown as Promise<{
      default: pdfMakeStatic;
    }>;
    this.pdfMake$.then((pdfMakeObj) => (this.pdfMake = pdfMakeObj.default));
  }

  async createPdfHandle$(templateName: string, data: unknown) {
    const template = await this.dataService.loadDocumentTemplate$(templateName);
    this.pdfMake = this.pdfMake || (await this.pdfMake$).default;

    if (!template) {
      throw new Error(`No template with name '${templateName}'.`);
    }

    const templateWithData = replaceAll(
      JSON.stringify(template),
      Object.entries(data as any)
    );
    const documentDefinitions = JSON.parse(
      templateWithData
    ) as TDocumentDefinitions;

    return this.toPdfHandle$(documentDefinitions);
  }

  private async createPdf$<T>(
    templateName: string,
    data: unknown,
    postCreate: (pdf: TCreatedPdf) => T
  ) {
    const template = await this.dataService.loadDocumentTemplate$(templateName);
    this.pdfMake = this.pdfMake || (await this.pdfMake$).default;

    if (!template) {
      throw new Error(`No template with name '${templateName}'.`);
    }

    const templateWithData = replaceAll(
      JSON.stringify(template),
      Object.entries(data as any)
    );
    const documentDefinition = JSON.parse(
      templateWithData
    ) as TDocumentDefinitions;

    if (
      documentDefinition.header &&
      typeof documentDefinition.header !== 'function'
    )
      documentDefinition.header = makeDynamic(documentDefinition.header);
    if (
      documentDefinition.footer &&
      typeof documentDefinition.footer !== 'function'
    )
      documentDefinition.footer = makeDynamic(documentDefinition.footer);

    return postCreate(this.pdfMake.createPdf(documentDefinition));
  }

  async createDocument$(templateName: string, data: unknown) {
    return this.createPdf$(
      templateName,
      data,
      (pdf) =>
        new Promise((resolve, reject) => {
          pdf.getBlob((blob) => resolve(blob));
        })
    );
  }

  async downloadDocument$(templateName: string, data: unknown, name: string) {
    return this.createPdf$(templateName, data, (pdf) =>
      pdf.download(`${name}.pdf`)
    );
  }

  async toPdfHandle$(documentDefinitions: TDocumentDefinitions) {
    this.pdfMake = this.pdfMake || (await this.pdfMake$).default;
    const clone = (o: unknown) => JSON.parse(JSON.stringify(o));
    const makePdf = () => {
      // documentDefinitions are corrupted when pdf is created - clone definitions to be used once
      const dd = clone(documentDefinitions);

      if (dd.header && typeof dd.header !== 'function')
        dd.header = makeDynamic(dd.header);
      if (dd.footer && typeof dd.footer !== 'function')
        dd.footer = makeDynamic(dd.footer);

      return this.pdfMake && this.pdfMake.createPdf(dd);
    };

    return {
      pdf: () => makePdf(),

      download: (fileName?: string) => {
        const pdf = makePdf();
        return pdf && pdf.download(fileName ?? '');
      },

      open: () => {
        const win = window.open('', '_blank');
        const pdf = makePdf();
        if (pdf) {
          pdf.open({}, win);
        }
      },

      print: () => {
        const pdf = makePdf();
        if (pdf) {
          pdf.print();
        }
      },

      asBase64Async: () =>
        new Promise((resolve, reject) => {
          const pdf = makePdf();
          try {
            if (pdf) {
              pdf.getBase64((blob: any) => resolve(blob));
            }
          } catch (err) {
            reject(err);
          }
        }),

      asBlobAsync: () =>
        new Promise((resolve, reject) => {
          const pdf = makePdf();
          try {
            if (pdf) {
              pdf.getBlob((blob: any) => resolve(blob));
            }
          } catch (err) {
            reject(err);
          }
        }),

      asBufferAsync: () =>
        new Promise((resolve, reject) => {
          const pdf = makePdf();
          try {
            if (pdf) {
              pdf.getBuffer((blob: any) => resolve(blob));
            }
          } catch (err) {
            reject(err);
          }
        }),

      asDataUrlAsync: () =>
        new Promise((resolve, reject) => {
          const pdf = makePdf();
          try {
            if (pdf) {
              pdf.getDataUrl((url: any) => resolve(url));
            }
          } catch (err) {
            reject(err);
          }
        }),
    };
  }
}

function replaceAll(
  template: string,
  items: [string, unknown][],
  replacePattern = (key: string) => `<@${key}@>`
) {
  let tmpTemplate = template;
  items.forEach(([key, value]) => {
    if (value) {
      tmpTemplate = tmpTemplate
        .split(replacePattern(key))
        .join((value as any).toString());
    }
  });

  return tmpTemplate;
}

function makeDynamic(content: Content): DynamicContent {
  return function (currentPage, pageCount) {
    return JSON.parse(
      JSON.stringify(content)
        .split('<@currentPage@>')
        .join(currentPage.toString())
        .split('<@pageCount@>')
        .join(pageCount.toString())
    );
  };
}
