import { Injectable, Renderer2 } from '@angular/core';
import { urlJoin } from '@app/core/functions/string-functions';
import { Log } from '@app/shared/constants/app-constants';
import { environment } from 'src/environments/environment';
import { Events } from '../models/events';
import {
  AgentMessagePayLoad,
  ConsultancyLoadedPayLoad,
  EmployeeDataLoadedPayload,
  Message,
} from '../models/messages';
import { ApplicationInsightsService } from '../services/application-insights.service';
import { MessagingService } from '../services/messaging.service';
import {
  Consultancy,
  ConsultancyData,
  ConsultancyStatus,
  createConsultancy,
} from './models/data/consultancy.model';
import { ProjectInfoExt } from './models/data/projectInfo.model';
import { SessionQuery } from './session.query';
import { SessionService } from './session.service';

const apiUri = environment.baseUri;
let id: string | undefined;
let userId: string | undefined;

@Injectable({
  providedIn: 'root',
})
export class ConsultancyService {
  private isConsultancyCleanInner = false;
  private projectInfo!: ProjectInfoExt;
  private agentId = '';
  currentScreenId?: string;
  currentStatus = ConsultancyStatus.incomplete;
  currentLanguage: string = "";
  private decryptionKeyHash: string = "";

  constructor(
    // private scriptService: ScriptService,
    private messagingService: MessagingService,
    private sessionService: SessionService,
    private query: SessionQuery,
    private appInsightsService: ApplicationInsightsService
  ) {
    this.query.agent$.subscribe(
      (agent) => (this.agentId = agent.id.toString())
    );
    this.query.project$.subscribe(
      (project) =>
        (this.projectInfo = {
          ...project.projectInfo,
          id: project.id as string,
        })
    );

    this.messagingService.on<AgentMessagePayLoad>(
      Message.AgentMessage,
      (payload) => (this.agentId = payload.agentId)
    );
    this.messagingService.on<ConsultancyLoadedPayLoad>(
      Message.ConsultancyLoaded,
      (payload) => {
        id = payload.consultancyId;
        userId = payload.userId;
        this.sessionService.updateUserId(payload.userId);
      }
    );
    this.messagingService.on<EmployeeDataLoadedPayload>(
      Message.EmployeeDataLoaded,
      (payload) => {
        this.decryptionKeyHash = payload.decryptionKeyHash || "";
        userId = payload.userId;
        this.sessionService.updateUserId(payload.userId);
      }
    );
  }

  updateConsultancyData(consultancyData: ConsultancyData) {
    this.sessionService.updateConsultancyData(consultancyData);
    this.isConsultancyCleanInner = false;
    Log.debug('consultancy data updated in story:');
    Log.debug(consultancyData);
  }

  async saveConsultancyData$(
    consultancyData: ConsultancyData,
    status: ConsultancyStatus = this.currentStatus //ConsultancyStatus.accepted
  ) {
    this.appInsightsService.startLogEvent(Events.saveConsultancy);
    this.currentStatus = status;
    const wrappedConsultancy = this.wrapConsultancyObject(
      consultancyData,
      this.currentScreenId,
      status,
      userId,
      this.currentLanguage
    );

    await this.sessionService.saveConsultancy$(wrappedConsultancy).toPromise();
    this.isConsultancyCleanInner = true;
    Log.debug('consultancy data saved:');
    Log.debug(wrappedConsultancy);

    this.appInsightsService.endLogEvent(Events.saveConsultancy, {
      status: wrappedConsultancy.status,
      id: wrappedConsultancy.id.toString(),
    });
  }

  emergencySave(consultancyData: ConsultancyData, wasError: boolean) {
    userId = userId || this.query.getValue().userId;

    const consultancy = this.wrapConsultancyObject(
      consultancyData,
      this.currentScreenId,
      wasError ? ConsultancyStatus.error : this.currentStatus,
      userId,
      this.currentLanguage
    );

    const url = urlJoin(apiUri, 'Consultancy', consultancy.id.toString());

    navigator.sendBeacon(url, JSON.stringify(consultancy));
    this.isConsultancyCleanInner = true;
  }

  isConsultancyClean = () => this.isConsultancyCleanInner;

  private wrapConsultancyObject(
    consultancyData: ConsultancyData,
    lastScreenId: string | undefined,
    status: ConsultancyStatus,
    _userId: string | undefined,
    language: string = ""
  ): Consultancy {
    const { documents, ...dataWithoutDocuments } = consultancyData;
    const consultancy = createConsultancy(
      {
        consultancyData: dataWithoutDocuments,
        status,
        decryptionKeyHash: this.decryptionKeyHash,
        lastScreen: lastScreenId,
        language: language,
        userId: _userId || this.query.getValue().userId,
        projectType: this.projectInfo.type,
        projectName: this.projectInfo.name,
        projectId: this.projectInfo.id,
        agentId: this.agentId,
        projectIdentifier: this.projectInfo.projectIdentifier,
        documents: consultancyData && consultancyData.documents,
      },
      id
    );
    id = consultancy.id as string;

    return consultancy;
  }
}
