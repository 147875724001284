import { throwError } from 'rxjs';
import { ErrorType } from '../constants/error-types';
import { Message, RaiseErrorMessagePayLoad } from '../models/messages';
import { MessagingService } from './messaging.service';

export class BaseDataService {
  constructor(protected messagingService: MessagingService) {}

  protected handleError(
    error: any,
    reason: string,
    errorType: ErrorType = ErrorType.Unexpected
  ) {
    const errorMessage =
      error.error instanceof ErrorEvent
        ? `Error ${reason}: ${error.error.message}` // client-side error
        : `Error ${reason} - Code: ${error.status}\nMessage: ${error.message}`; // server-side error

    this.raiseError(errorType, errorMessage);

    error.isHandled = true;

    return throwError(error);
  }

  protected raiseError(errorType: ErrorType, message?: string) {
    this.messagingService.broadcast<RaiseErrorMessagePayLoad>(
      Message.RaiseErrorMessage,
      { errorType, message }
    );
  }
}
