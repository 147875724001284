export function toPromise<T>(
  func: (resolve: (value?: T) => void, reject: (reason: any) => void) => void
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    try {
      func(resolve, reject);
    } catch (ex) {
      reject(ex);
    }
  });
}
