<div class="container text-center">
  <div class="headline">
    <h1 class="mat-h1">{{ titleText }}</h1>
    <span class="mat-body">{{ errorText }}</span>
  </div>
  <div class="picture">
    <img
      src="assets/img/Smiley_green_alien_big_eyes.svg"
      alt="Sorry für den galaktischen Fehler!"
      title="Sorry für den galaktischen Fehler"
    />
  </div>
  <div class="text mat-body">Das hätte nicht passieren dürfen.</div>
</div>
