import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ContactComponent } from '../login-page/infos/contact/contact.component';
import { PrivacyComponent } from '../login-page/infos/privacy/privacy.component';
import { SystemRequirementsComponent } from '../login-page/infos/system-requirements/system-requirements.component';

@Component({
  selector: 'dwerk-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss'],
})
export class CommonFooterComponent implements OnInit {
  constructor(private bottomSheet: MatBottomSheet) {}

  ngOnInit() {}

  showInfos(infoType: string) {
    const infoComponentDict = {
      systemRequirements: SystemRequirementsComponent,
      privacyRegulation: PrivacyComponent,
      legalNotice: ContactComponent,
    } as any;

    const infoComponent = infoComponentDict[infoType];

    if (infoComponent) {
      this.bottomSheet.open(infoComponent);
    }
  }
}
