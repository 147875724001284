<h1>Systemvoraussetzungen</h1>

<p>
  Bitte stellen Sie sicher, dass Sie JavaScript aktiviert haben, um unser
  interaktives Video in vollem Umfang erleben zu k&ouml;nnen.
</p>

<p>
  Unser interaktives Video l&auml;sst sich auf Ger&auml;ten (Devices) mit
  HTML5-MP4-Video-Playback-Unterst&uuml;tzung abspielen.
</p>

<p>Kompatible Betriebssysteme und Browser:</p>

<p>
  <img
    alt="Chrome"
    src="assets/img/browsers/chrome.png"
    title="Google Chrome"
  /><img
    alt="Firefox"
    src="assets/img/browsers/firefox.png"
    title="Mozilla Firefox"
  /><img
    alt="Microsoft Edge"
    src="assets/img/browsers/edge.png"
    title="Microsoft Edge"
  /><img
    alt="Safari"
    src="assets/img/browsers/safari.png"
    title="Apple Safari"
  />
</p>

<p>
  <img alt="iOS" src="assets/img/browsers/ios.png" title="iOS" /><img
    alt="Android"
    src="assets/img/browsers/android.png"
    title="Android"
  />
</p>

<p>
  <ins>Smartphones</ins>: Unser System ist nicht f&uuml;r die Nutzung auf
  Smartphones optimiert.
</p>

<p>
  <ins>Internet Explorer</ins>: Dieser Browser wird seit Ende 2016 nicht mehr
  von Microsoft weiterentwickelt und unterst&uuml;tzt. Unser System ist daher
  nicht auf den Gebrauch mit diesem Browser ausgerichtet.
</p>

<p>
  <ins>Safari f&uuml;r Windows</ins>: Dieser Browser wird nicht mehr von Apple
  weiterentwickelt und unterst&uuml;tzt. Unser System ist daher nicht auf den
  Gebrauch mit diesem Browser ausgerichtet.
</p>

<p><strong>Tipps zum Video-Streaming</strong></p>

<p>
  Zum einwandfreien Abspielen unseres interaktiven Videos ben&ouml;tigen Sie
  idealerweise eine Breitband-Internet-Verbindung. Sollte Ihre
  Breitband-Verbindung nicht schnell genug sein, kann unser interaktives Video
  ruckeln. Wir empfehlen eine Downstream-Brandbreite von mindestens 1.500kbit/s.
</p>

<p>
  Sie k&ouml;nnen Ihre Downstream-Bandbreite auf diversen Webseiten pr&uuml;fen.
  Suchen Sie daf&uuml;r im Internet nach &ldquo;Bandbreitentest&rdquo; oder
  &ldquo;Speedtest&rdquo;
</p>

<p>
  Hier einige Tipps, wenn unser Video nicht einwandfrei auf Ihrem Ger&auml;t
  laufen sollte:
</p>

<ul>
  <li>
    Ihre Video-Download-Geschwindigkeit ist langsam oder instabil. Pausieren Sie
    das Video bis das Video vollst&auml;ndig geladen ist und starten Sie das
    Video erneut.
  </li>
  <li>
    Sollten Sie das Video &uuml;ber eine W-LAN-Verbindung anschauen und das
    Video ruckelt, versuchen Sie das Video &uuml;ber eine LAN-Verbindung
    anzusehen oder positionieren Sie Ihr Ger&auml;t n&auml;her am W-LAN-Router,
    um die W-LAN-Verbindung zu verbessern.
  </li>
  <li>
    Sollten Sie zeitgleich andere Anwendungen nutzen, die eine hohe Bandbreite
    ben&ouml;tigen, (z.B. Outlook, andere Downloads, Internet-Telefondienste wie
    z.B. Skype), pr&uuml;fen Sie bitte, ob Sie diese pausieren k&ouml;nnen, um
    gen&uuml;gend Bandbreite f&uuml;r unser interaktives Video zur
    Verf&uuml;gung zu haben.
  </li>
  <li>
    Sollten Sie Ihre Internetverbindung mit anderen Nutzern teilen, z.B. in
    einem (Firmen-) Netzwerk, pr&uuml;fen Sie, ob diese andere
    bandbreiten-intensive Programme nutzen, w&auml;hrend Sie unser interaktives
    Video anschauen.
  </li>
  <li>
    Wenn unser interaktives Video noch immer ruckelt, gibt es ggf. Probleme mit
    Ihrer CPU-Leistung. Versuchen Sie CPU-intensive Programme zu schlie&szlig;en
    oder Ihr Ger&auml;t neu zu starten.
  </li>
</ul>

<p><strong>Unser interaktives Video l&auml;sst sich nicht abspielen</strong></p>

<p>
  Bitte stellen Sie sicher, dass Sie JavaScript mit
  HTML5-MP4-Video-Playback-Unterst&uuml;tzung aktiviert haben. Pr&uuml;fen Sie
  auch, ob Sie Videos auf Webseiten wie
  <a href="http://youtube.com" target="_blank">YouTube</a> abspielen
  k&ouml;nnen. Wenn Sie Videos auf anderen Webseiten abspielen k&ouml;nnen und
  trotzdem Schwierigkeiten haben, unser interaktives Video abzuspielen, kann
  dies durch individuelle Einstellungen Ihres Anti-Virus-Programms oder
  installierter Plug-Ins verursacht werden. Bitte senden Sie uns f&uuml;r
  weitere Hilfe eine E-Mail an
  <a href="mailto:webmaster@dwerk.de">webmaster@dwerk.de</a>.
</p>
