import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LayoutModule } from '@app/layouts/layouts.module';
import { SharedModule } from '@app/shared';
import { MaterialModule } from '@app/shared/material.module';
import { AtCdnPipe } from '@app/shared/pipes/at-cdn.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PlayerListComponent } from './components/video/player-list/player-list.component';
import { VideoComponent } from './components/video/video.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    LayoutModule,
  ],
  declarations: [
    VideoComponent,
    PlayerListComponent,
    AtCdnPipe,
    FooterComponent,
    ProgressBarComponent,
  ],
  exports: [
    VideoComponent,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
})
export class StoryBoardModule {}
