import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import {
  getOriginWithoutWWW,
  getSubdomain,
} from '@app/core/functions/locationFunktions';
import { BaseComponent } from '@app/shared/components/base-component';
import { ErrorType } from '@app/shared/constants/error-types';
import { LoginErrorMessages } from '@app/shared/constants/login-error-messages';
import { Message } from '@app/shared/models/messages';
import { MessagingService } from '@app/shared/services/messaging.service';
import {
  AnonymousAuthData,
  AuthData,
  AuthError,
  AuthResult,
  AuthService,
} from '../../services/auth.service';

interface LoginData {
  projectShortName: string;
  userName: string;
  password: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dwerk-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent extends BaseComponent
  implements OnInit, OnDestroy {
  origin = '';
  projectId = '';
  isLoginRequired = false;
  errorMsg = '';
  hidePassword = true;
  loginForm: FormGroup;
  isExecutingLogin = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private messagingService: MessagingService
  ) {
    super();

    this.loginForm = this.formBuilder.group({
      projectShortName: new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
      userName: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  get projectShortName(): AbstractControl | null {
    return this.loginForm.get('projectShortName');
  }

  get userName(): AbstractControl | null {
    return this.loginForm.get('userName');
  }
  get password(): AbstractControl | null {
    return this.loginForm.get('password');
  }

  async ngOnInit() {
    this.origin = window.origin;


    let authResult: AuthResult;
    const loginParams = {
      shortName: this.activeRoute.snapshot.queryParams.shortName,
      userName: this.activeRoute.snapshot.queryParams.userName,
      password: this.activeRoute.snapshot.queryParams.password
    };

    // console.log("loginParams:", loginParams);

    if (loginParams.shortName &&
      loginParams.userName &&
      loginParams.password) {
      // try to log in with data from URL parameters
      authResult = await this.authorize$({
        // url + query params if provided
        url: getOriginWithoutWWW(this.origin),
        projectId: getProjectId(this.activeRoute.snapshot),
        // login data
        // projectShortName might be disabled => not contained in submitted data
        projectShortName: loginParams.shortName,
        userName: loginParams.userName,
        password: loginParams.password,
      });
    } else {
      // try to get JWT token without user login
      authResult = await this.authorizeAnonymously$({
        url: getOriginWithoutWWW(this.origin),
        projectId: getProjectId(this.activeRoute.snapshot),
      });
    }

    const payload = this.authService.tokenPayload;

    // console.log("authResult:", authResult);
    // console.log("isAuthorized:", this.authService.isAuthorized);
    // console.log("payload:", payload);

    if (this.authService.isAuthorized && payload) {
      this.showVideo(
        this.activeRoute.snapshot.queryParams.agentId ||
        (authResult.agentIdentifiers && authResult.agentIdentifiers[0]) ||
        '',
        payload.projectIdentifier
      );
    } else if (authResult.error === AuthError.LoginRequired || authResult.error === AuthError.LoginError) {
      // force user login
      if (authResult.error === AuthError.LoginError) {
        if (loginParams.shortName) {
          this.loginForm.controls.projectShortName.setValue(loginParams.shortName);
          this.loginForm.controls.userName.setValue(loginParams.userName);
          this.loginForm.controls.password.setValue(loginParams.password);
          this.errorMsg = this.getLoginPageErrorText(authResult.error);
          this.changeDetector.markForCheck();
        }
      }
      if (authResult.projectShortName) {
        this.loginForm.controls.projectShortName.setValue(
          authResult.projectShortName
        );
        this.loginForm.controls.projectShortName.disable();
      }
      this.projectId = authResult.projectIdentifier || '';
      this.isLoginRequired = true;
      this.changeDetector.markForCheck();
    } else {
      const errorType = this.getErrorPageErrorType(authResult.error);
      this.messagingService.broadcast(Message.RaiseErrorMessage, { errorType });
    }
  }

  async onSubmit$(loginData: LoginData) {
    if (!(loginData.userName && loginData.password && this.projectShortName)) {
      return;
    }

    this.loginForm.setErrors(null);
    const timer = this.setExecutingLogin();
    const authResult: AuthResult = await this.authorize$({
      // url + query params if provided
      url: getOriginWithoutWWW(this.origin),
      projectId: getProjectId(this.activeRoute.snapshot),
      // login data
      // projectShortName might be disabled => not contained in submitted data
      projectShortName: this.loginForm.getRawValue().projectShortName,
      userName: loginData.userName,
      password: loginData.password,
    });

    const payload = this.authService.tokenPayload;

    if (this.authService.isAuthorized && payload) {
      this.showVideo(
        this.activeRoute.snapshot.queryParams.agentId ||
        (authResult.agentIdentifiers && authResult.agentIdentifiers[0]) ||
        '',
        payload.projectIdentifier
      );
    } else {
      this.errorMsg = this.getLoginPageErrorText(authResult.error);
      this.changeDetector.markForCheck();
    }

    this.unsetExecutingLogin(timer);
  }

  setExecutingLogin(): NodeJS.Timer {
    return setTimeout(() => {
      this.isExecutingLogin = true;
    }, 400);
  }

  unsetExecutingLogin(handle: NodeJS.Timer) {
    this.isExecutingLogin = false;
    clearTimeout(handle);
  }

  public async authorizeAnonymously$(
    credentials: AnonymousAuthData
  ): Promise<AuthResult> {
    return this.authService.authorizeAnonymously$(credentials);
  }

  public async authorize$(credentials: AuthData): Promise<AuthResult> {
    return this.authService.authorize$(credentials);
  }

  private showVideo(agentId: string, projectId: string): void {
    this.router.navigate(['video'], {
      queryParams: { ...this.activeRoute.snapshot.queryParams, agentId, projectId },
      skipLocationChange: true,
      replaceUrl: true, //TODO: Check
    });
  }

  /**
   * Get error text to be displayed locally within login form
   * @param authError error from authentication
   */
  private getLoginPageErrorText(authError?: AuthError): string {
    return authError === AuthError.ProjectError
      ? LoginErrorMessages.InvalidProject
      : authError === AuthError.LoginError
        ? LoginErrorMessages.LoginError
        : LoginErrorMessages.Unexpected;
  }

  /**
   * Get error type to display general error on error page
   * @param authError error from authentication
   */
  private getErrorPageErrorType(authError?: AuthError): ErrorType {
    return authError === AuthError.ProjectError
      ? ErrorType.InvalidProject
      : ErrorType.Unexpected;
  }
}

function getProjectId(routeSnapshot: ActivatedRouteSnapshot) {
  // let subDomain = getSubdomain();
  // if (subDomain === 'www') {
  //   subDomain = undefined;
  // }

  // return subDomain || routeSnapshot.queryParams.projectId || '';
  return routeSnapshot.queryParams.projectId || '';
}
