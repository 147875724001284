import { ThemeInfo } from '@app/shared/models/theme.model';
import { Agent } from '@app/shared/state/models/data/agent.model';
import { StoryBoard } from '../screens/storyBoard.model';
import { EntityModel } from './entity.model';
import { InfoTexts } from './infoTexts.model';
import { ProjectInfo } from './projectInfo.model';

// tslint:disable-next-line: interface-name
export interface Project extends EntityModel {
  theme: ThemeInfo;
  // footerTexts: FooterTexts;
  infoTexts: InfoTexts;
  fileInfos: FileInfo;
  projectInfo: ProjectInfo;
  paths: PathInfo;
  storyboard: StoryBoard;
  status: ProjectStatus;

  agent: Agent;
}

export interface FooterTexts {
  termsOfUse: string;
  privacyPolicy: string;
  editorialInfo: string;
}

export interface FileInfo {
  file1?: InfoFile;
  file2?: InfoFile;
}

export interface InfoFile {
  path: string;
  tooltip: string;
}

export interface PathInfo {
  cdnRoot: string;
  javascript: string;
  css: string;
}

/**
 * A factory function that creates Project
 */
export function createProject() {
  return {} as Project;
}

export enum ProjectStatus {
  open = 'open',
  inProgress = 'inProgress',
  activated = 'activated',
}

const projectTypesWithAgents = ['kvz'];
