import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { urlJoin } from '@app/core/functions/string-functions';
import { isNil } from '@app/core/functions/type-guards';
import { Maybe } from '@app/core/true-myth-monads';
import { of } from '@app/core/true-myth-monads/maybe';
import { PlayerControl } from '@app/modules/storyboard/models/player-control';
import {
  Sequence,
  SequenceInternal,
} from '@app/shared/state/models/screens/story.model';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VideoPlayState } from '../state/models/video.model';

@Component({
  selector: 'dwerk-player-list',
  templateUrl: './player-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./player-list.component.scss'],
})
export class PlayerListComponent
  implements PlayerControl, OnInit, AfterViewChecked
{
  // #region Properties (13)

  private _isSoundOn = true;
  private isVideoMuted = false;
  private activeIdInternal = '';
  private isFullscreenInternal = false;
  private sequencesInternal: SequenceInternal[] = [];
  //private languageInternal: string = '';
  @Input() language: string = '';

  isVideoWating = false;
  hasVideoEnded = false;
  activeSequence?: Sequence;
  @Output() public controler: EventEmitter<PlayerControl> = new EventEmitter();
  onTimeUpdate$ = new Subject<[Sequence, number]>();
  onVideoLoaded$ = new Subject<Sequence>();
  onVideoPlaying$ = new Subject<[Sequence, VideoPlayState]>();
  rootSequence?: SequenceInternal;
  poster?: string;
  private isVideoPlaying = false;
  private lastPlayerTime?: number;
  private lastStutterTestTime = Date.now();
  private now = Date.now();

  public shouldShowPoster = true;
  @ViewChild('videoplayer', { static: true })
  videoPlayer!: ElementRef<HTMLVideoElement>;
  public videoTimer = 0;

  // #endregion Properties (13)

  // #region Constructors (1)

  constructor(private changeDetector: ChangeDetectorRef) {
    setInterval(() => {
      this.now = Date.now();
      changeDetector.markForCheck();
    }, 100);
  }

  // #endregion Constructors (1)

  // #region Public Accessors (11)

  public get activeId(): string {
    return this.activeIdInternal;
  }

  public set activeId(value: string) {
    if (this.activeIdInternal !== value) {
      this.activeSequence = this.sequences.find((s) => s.id === value);
      this.rootSequence = this.rootSequence || this.activeSequence;

      this.poster =
        this.poster || (this.rootSequence && this.rootSequence.poster);
      this.activeIdInternal = value;

      this.isVideoWating = false;

      this.changeDetector.markForCheck();
    }
  }

  public get activePlayer(): HTMLVideoElement | null {
    return this.getPlayer(this.activeId);
  }

  public get isFullscreen(): boolean {
    return this.isFullscreenInternal;
  }

  public set isFullscreen(value: boolean) {
    this.isFullscreenInternal = value;
    this.changeDetector.markForCheck();
  }

  public get isSoundOn(): boolean {
    return this._isSoundOn;
  }

  public set isSoundOn(value: boolean) {
    this._isSoundOn = value;
    this.setSoundStatus(this._isSoundOn);
  }

  public get shouldShowSpinner() {
    const HAVE_ENOUGH_DATA = 4;

    const isVideoWaiting =
      this.isVideoStuttering ||
      (!this.isVideoPlaying &&
        !this.hasVideoEnded &&
        this.activePlayer &&
        this.activePlayer.readyState !== HAVE_ENOUGH_DATA);

    if (isVideoWaiting) {
      this.videoTimer = this.videoTimer || this.now;

      return this.now - this.videoTimer > 650;
    }
    this.videoTimer = 0;
    return false;
  }

  public get isVideoStuttering() {
    if (
      this.activePlayer &&
      this.isVideoPlaying &&
      this.lastStutterTestTime + 150 < this.now
    ) {
      const currentTime = this.activePlayer.currentTime || 0.01;

      if (this.lastPlayerTime === currentTime) {
        return true;
      }
      this.lastStutterTestTime = this.now;
      this.lastPlayerTime = this.activePlayer.currentTime || 0;
    }
    return false;
  }

  public get mActivePlayer(): Maybe<HTMLVideoElement> {
    return of(this.activePlayer);
  }

  public get sequences(): SequenceInternal[] {
    return this.sequencesInternal;
  }

  public set sequences(value: SequenceInternal[]) {
    this.sequencesInternal = (value || []).filter((seq) => !!seq);
    this.changeDetector.markForCheck();
  }

  // #endregion Public Accessors (11)

  // #region Public Methods (15)

  public getId(n: number, currSequence: Sequence) {
    const id = currSequence && currSequence.id;
    return id || '';
  }

  public getPlayer(id: string): HTMLVideoElement | null {
    return id ? (document.getElementById(id) as HTMLVideoElement) : null;
  }

  public getTimeUri(currSequence: Sequence) {
    if (!currSequence) {
      return '';
    }

    let videoUri = currSequence.video;
    let start = currSequence.start || 0;
    let end = currSequence.end;
    if (
      currSequence.videos &&
      Array.isArray(currSequence.videos) &&
      this.language !== ''
    ) {
      let video = currSequence.videos.find(
        (video) => video.lang == this.language
      );
      if (video && video.uri) {
        videoUri = video.uri;
        start = video.start || 0;
        end = video.end;
      }
    }

    const url = urlJoin(environment.projectCdnBaseUri, videoUri);
    const endstr = end ? `,${end}` : '';
    let timeUri = `${url}#t=${start}${endstr}`;

    return timeUri;
  }

  // public getUri(currSequence: Sequence) {
  //   if (!currSequence) {
  //     return '';
  //   }

  //   const url = urlJoin(environment.projectCdnBaseUri, currSequence.video);

  //   return url;
  // }

  public goToFullscreen(isFullscreen: boolean = true) {
    this.isFullscreen = isFullscreen;
  }

  public ngAfterViewChecked(): void {}

  public ngOnInit() {
    this.controler.emit(this);
  }

  public onTimeUpdate($event: Event, currSequence: Sequence) {
    const player = $event.target as HTMLVideoElement;

    this.onTimeUpdate$.next([currSequence, player.currentTime]);
  }

  public onVideoEnded(currSequence: Sequence) {
    this.hasVideoEnded = true;
    this.onVideoPlaying$.next([currSequence, VideoPlayState.Ended]);
    // this.onTimeUpdate$.next([currSequence, currSequence.end + 1]);
  }

  public onVideoLoaded($event: Event, currSequence: Sequence) {
    const player = $event.target as HTMLVideoElement;
    this.onVideoLoaded$.next(currSequence);
    this.changeDetector.markForCheck();
  }

  public onVideoPlaying(isPlaying: boolean, currSequence: Sequence) {
    this.shouldShowPoster = this.shouldShowPoster && !isPlaying;
    this.isVideoPlaying = isPlaying;
    this.hasVideoEnded = this.hasVideoEnded && !isPlaying;
    this.onVideoPlaying$.next([
      currSequence,
      isPlaying ? VideoPlayState.Playing : VideoPlayState.Paused,
    ]);

    this.isVideoWating = false;
  }

  public pause() {
    this.mActivePlayer.ifJust<HTMLVideoElement>((player) => player.pause());
  }

  public play(option?: {
    id?: string;
    shouldStartAgain?: boolean;
  }): Promise<void> {
    const { id, shouldStartAgain } = option || {
      id: undefined,
      shouldStartAgain: false,
    };

    if (isNil(id) || id === this.activeId) {
      if (shouldStartAgain && this.activePlayer) {
        this.activePlayer.currentTime =
          (this.activeSequence && this.activeSequence.start) || 0;
        this.activePlayer.muted = this.isVideoMuted;
      }
      return (
        (this.activePlayer &&
          this.activePlayer.play().catch((err) => console.warn(err))) ??
        Promise.resolve()
      );
    } else {
      this.pause();
      this.activeId = id || this.activeId;

      return this.play({ id, shouldStartAgain });
    }
  }

  public setNewPlayingTime(playingTime: number) {
    this.mActivePlayer.ifJust((player) => (player.currentTime = playingTime));
  }

  public setSequenceData(sequences: Sequence[], activeId: string) {
    this.sequences = sequences;
    this.activeId = activeId;
  }

  public setSoundStatus(isSoundOn: boolean) {
    if (this.activePlayer) {
      this.activePlayer.muted = !isSoundOn;
      this.isVideoMuted = this.activePlayer.muted;
    }
  }

  // #endregion Public Methods (15)
}
