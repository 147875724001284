import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../base-component';

@Component({
  selector: 'dwerk-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss'],
})
export class LogoutPageComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
