<h1><strong>Datenschutzerkl&auml;rung (Art. 13 und Art. 14 DSGVO) </strong></h1>

<p><strong>ALLGEMEIN</strong></p>

<p>
  <strong
    >Information &uuml;ber die Erhebung personenbezogener Daten und Kontaktdaten
    des Verantwortlichen</strong
  >
</p>

<p>
  Diese Datenschutzerkl&auml;rung kl&auml;rt Sie &uuml;ber die Art, den Umfang
  und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz
  &bdquo;Daten&ldquo;) im Rahmen der Erbringung unserer Leistungen sowie
  innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
  Funktionen und Inhalte.
</p>

<p>
  Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
  &bdquo;Verarbeitung&ldquo; oder &bdquo;Verantwortlicher&ldquo; verweisen wir
  auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).&nbsp;
</p>

<p>&nbsp;</p>

<p>
  <a name="Verantwortlicher"><strong>Verantwortlicher</strong></a
  ><strong> </strong>
</p>

<p>
  dWERK GmbH &amp; Co. KG<br />
  Darmst&auml;dter Str. 170<br />
  D-64625 Bensheim<br />
  Telefon: +49 7242 896 07 04
</p>

<p>Gesch&auml;ftsf&uuml;hrer Torsten Burkart</p>

<p>
  Impressum:
  <a href="https://dwerk.de/impressum.html">https://dwerk.de/impressum.html</a>
</p>

<p>&nbsp;</p>

<p>
  <a name="Datenschutzbeauftragter"><strong>Datenschutzbeauftragter</strong></a>
</p>

<p>
  Frank Berns<br />
  Konzept 17 GmbH<br />
  Westring 3<br />
  24850 Schuby
</p>

<p>Telefon: +49 4621 530 40 50&nbsp;</p>

<p>
  E-Mail: <a href="mailto:mail@konzept17.de">mail@konzept17.de</a><br />
  &nbsp;
</p>

<p><strong>I Bereitstellung der Website und Erstellung von Logfiles</strong></p>

<p>
  <strong>1. Beschreibung und Umfang der Datenverarbeitung</strong><br />
  Bei jedem Aufruf unserer Website erfasst unser System automatisiert Daten und
  Informationen vom Computersystem des aufrufenden Rechners. Folgende Daten
  werden hierbei erhoben:
</p>

<p>
  (1) Informationen &uuml;ber den Browsertyp und die verwendete Version<br />
  (2) Das Betriebssystem des Nutzers<br />
  (3) Den Internet-Service-Provider des Nutzers<br />
  (4) Die IP-Adresse des Nutzers<br />
  (5) Datum und Uhrzeit des Zugriffs<br />
  (6) Websites, von denen das System des Nutzers auf unsere Internetseite
  gelangt<br />
  (7) Websites, die vom System des Nutzers &uuml;ber unsere Website aufgerufen
  werden
</p>

<p>
  Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine
  Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des
  Nutzers findet nicht statt.
</p>

<p>
  <strong>2. Rechtsgrundlage f&uuml;r die Datenverarbeitung</strong><br />
  Rechtsgrundlage f&uuml;r die vor&uuml;bergehende Speicherung der Daten und der
  Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
</p>

<p>
  <strong>3. Zweck der Datenverarbeitung</strong><br />
  Die vor&uuml;bergehende Speicherung der IP-Adresse durch das System ist
  notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu
  erm&ouml;glichen. Hierf&uuml;r muss die IP-Adresse des Nutzers f&uuml;r die
  Dauer der Sitzung gespeichert bleiben.
</p>

<p>
  Die Speicherung in Logfiles erfolgt, um die Funktionsf&auml;higkeit der
  Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der
  Website und zur Sicherstellung der Sicherheit unserer informationstechnischen
  Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem
  Zusammenhang nicht statt.
</p>

<p>
  In diesen Zwecken liegt auch unser berechtigtes Interesse an der
  Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
</p>

<p>
  <strong>4. Dauer der Speicherung</strong><br />
  Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes
  ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten
  zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung
  beendet ist.
</p>

<p>
  Im Falle der Speicherung der Daten in Logfiles ist dies nach sp&auml;testens
  sieben Tagen der Fall. Eine dar&uuml;berhinausgehende Speicherung ist
  m&ouml;glich. In diesem Fall werden die IP-Adressen der Nutzer gel&ouml;scht
  oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr
  m&ouml;glich ist.
</p>

<p>
  <a name="Betroffenenrechte"><strong>II. Rechte </strong></a
  ><strong>der betroffenen Person</strong>
</p>

<p>
  Das geltende Datenschutzrecht gew&auml;hrt Ihnen gegen&uuml;ber dem
  Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten
  umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), &uuml;ber
  die wir Sie nachstehend informieren:
</p>

<ul>
  <li>
    <strong>Auskunftsrecht</strong> gem&auml;&szlig; Art. 15 DSGVO: Sie haben
    insbesondere ein Recht auf Auskunft &uuml;ber Ihre von uns verarbeiteten
    personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der
    verarbeiteten personenbezogenen Daten, die Empf&auml;nger oder Kategorien
    von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder
    werden, die geplante Speicherdauer bzw. die Kriterien f&uuml;r die
    Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
    L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch gegen die
    Verarbeitung, Beschwerde bei einer Aufsichtsbeh&ouml;rde, die Herkunft Ihrer
    Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen
    einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling
    und ggf. aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik
    und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer
    solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien
    gem&auml;&szlig; Art. 46 DSGVO bei Weiterleitung Ihrer Daten in
    Drittl&auml;nder bestehen;<br />
    &nbsp;
  </li>
  <li>
    Recht auf <strong>Berichtigung</strong> gem&auml;&szlig; Art. 16 DSGVO: Sie
    haben ein Recht auf unverz&uuml;gliche Berichtigung Sie betreffender
    unrichtiger Daten und/oder Vervollst&auml;ndigung Ihrer bei uns
    gespeicherten unvollst&auml;ndigen Daten;<br />
    &nbsp;
  </li>
  <li>
    Recht auf <strong>L&ouml;schung</strong> gem&auml;&szlig; Art. 17 DSGVO: Sie
    haben das Recht, die L&ouml;schung Ihrer personenbezogenen Daten bei
    Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses
    Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur
    Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und
    Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus
    Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung,
    Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich
    ist;<br />
    &nbsp;
  </li>
  <li>
    Recht auf
    <strong>Einschr&auml;nkung der Verarbeitung</strong> gem&auml;&szlig; Art.
    18 DSGVO: Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
    personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene
    Richtigkeit Ihrer Daten &uuml;berpr&uuml;ft wird, wenn Sie eine
    L&ouml;schung Ihrer Daten wegen unzul&auml;ssiger Datenverarbeitung ablehnen
    und stattdessen die Einschr&auml;nkung der Verarbeitung Ihrer Daten
    verlangen, wenn Sie Ihre Daten zur Geltendmachung, Aus&uuml;bung oder
    Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, nachdem wir diese
    Daten nach Zweckerreichung nicht mehr ben&ouml;tigen oder wenn Sie
    Widerspruch aus Gr&uuml;nden Ihrer besonderen Situation eingelegt haben,
    solange noch nicht feststeht, ob unsere berechtigten Gr&uuml;nde
    &uuml;berwiegen;<br />
    &nbsp;
  </li>
  <li>
    Recht auf <strong>Unterrichtung</strong> gem&auml;&szlig; Art. 19 DSGVO:
    Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung
    der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht, ist
    dieser verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden
    personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
    L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung
    mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit
    einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht das
    Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.<br />
    &nbsp;
  </li>
  <li>
    Recht auf <strong>Daten&uuml;bertragbarkeit</strong> gem&auml;&szlig; Art.
    20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns
    bereitgestellt haben, in einem strukturierten, g&auml;ngigen und
    maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung an einen
    anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;<br />
    &nbsp;
  </li>
  <li>
    Recht auf <strong>Widerruf</strong> erteilter Einwilligungen
    gem&auml;&szlig; Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal
    erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung
    f&uuml;r die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die
    betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern eine weitere
    Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen
    Verarbeitung gest&uuml;tzt werden kann. Durch den Widerruf der Einwilligung
    wird die Rechtm&auml;&szlig;igkeit, der aufgrund der Einwilligung bis zum
    Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt;<br />
    &nbsp;
  </li>
  <li>
    Recht auf <strong>Widerspruch</strong> gem&auml;&szlig; Art. 21 DSGVO: Wenn
    wir im Rahmen einer Interessenabw&auml;gung Ihre personenbezogenen Daten
    aufgrund unseres &uuml;berwiegenden berechtigten Interesses verarbeiten,
    haben Sie jederzeit das Recht, aus Gr&uuml;nden, die sich aus Ihrer
    besonderen Situation ergeben, gegen diese Verarbeitung Widerspruch mit
    Wirkung f&uuml;r die Zukunft einzulegen. Ihren Widerspruch k&ouml;nnen Sie
    uns auf den oben angegebenen Kontaktwegen zukommen lassen. Machen Sie von
    Ihrem Widerspruchsrecht Gebrauch, beenden wir die Verarbeitung der
    betroffenen Daten. Eine Weiterverarbeitung bleibt aber vorbehalten, wenn wir
    zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung
    nachweisen k&ouml;nnen, die Ihre Interessen, Grundrechte und Grundfreiheiten
    &uuml;berwiegen, oder wenn die Verarbeitung der Geltendmachung,
    Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient. Werden Ihre
    personenbezogenen Daten von uns verarbeitet, um Direktwerbung zu betreiben,
    haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung
    betreffender personenbezogener Daten zum Zwecke derartiger Werbung
    einzulegen. Sie k&ouml;nnen den Widerspruch wie oben beschrieben
    aus&uuml;ben. Machen Sie von Ihrem Widerspruchsrecht Gebrauch, beenden wir
    die Verarbeitung der betroffenen Daten zu Direktwerbezwecken.<br />
    &nbsp;
  </li>
  <li>
    Recht auf <strong>Beschwerde</strong> gem&auml;&szlig; Art. 77 DSGVO: Wenn
    Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
    personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben Sie -
    unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
    Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde,
    insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres
    Arbeitsplatzes oder des Ortes des mutma&szlig;lichen Versto&szlig;es.
  </li>
</ul>

<p>
  Wenn Sie der Auffassung sind, dass eine Datenverarbeitung gegen
  Datenschutzrecht verst&ouml;&szlig;t, haben Sie das Recht, sich bei einer
  Datenschutzaufsichtsbeh&ouml;rde zu beschweren.
</p>

<p>
  Eine Liste der deutschen Aufsichtsbeh&ouml;rden finden Sie unter folgendem
  Link:
</p>

<p>
  <a
    href="https://Bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
    >https://Bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a
  >
</p>
