<mat-toolbar [id]="'header-block'" color="primary">
  <div class="logo">
    <img id="logo-Img" />
  </div>
  <div class="text">
    <div>
      <h1>{{ title }}</h1>
      <h2>{{ underline }}</h2>
    </div>
  </div>
</mat-toolbar>
