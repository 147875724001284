export enum ErrorType {
  NotFound = 'NotFound',
  VideoRunError = 'VideoRunError',
  InvalidStatus = 'InvalidStatus',
  InvalidProject = 'InvalidProject',
  InvalidAgent = 'InvalidAgent',
  Unexpected = 'Unexpected',
  InvalidDate = 'InvalidDate',
  InvalidUrl = 'InvalidUrl',
  Default = 'Default',
  PrepareScreenError = 'PrepareScreenError',
}
