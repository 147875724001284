const isDate = (dateString: string) => !Number.isNaN(Date.parse(dateString));

export function toDate(dateString: string) {
  if (isDate(dateString)) {
    return new Date(dateString);
  }

  throw new Error(`Invalid Date String: ${dateString}`);
}

export function now() {
  return new Date();
}
export function nowIsoString() {
  return now().toISOString();
}