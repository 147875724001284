<video
  *ngIf="rootSequence"
  #videoplayer
  [src]="getTimeUri(rootSequence, '') | sanitize: 'base64'"
  [class.fullscreen]="isFullscreen"
  playsinline
>
  Your browser doesn't support the video tag.
</video>
<ng-container *ngFor="let sequence of sequences; trackBy: getId">
  <link
    rel="preload"
    [href]="getTimeUri(sequence) | sanitize: 'base64'"
    as="video"
  />
</ng-container>
<ng-container *ngFor="let sequence of sequences; trackBy: getId">
  <video
    #videoplayer
    [src]="getTimeUri(sequence) | sanitize: 'base64'"
    [attr.id]="sequence.id"
    [class.fullscreen]="isFullscreen"
    (canplaythrough)="onVideoLoaded($event, sequence)"
    (ended)="onVideoEnded(sequence)"
    (pause)="onVideoPlaying(false, sequence)"
    (play)="onVideoPlaying(true, sequence)"
    (playing)="onVideoPlaying(true, sequence)"
    (waiting)="isVideoWating = true"
    (timeupdate)="onTimeUpdate($event, sequence)"
    [class.hidden]="sequence.id !== activeId"
    preload="auto"
    playsinline
  >
    Your browser doesn't support the video tag.</video
  ><img
    [class.hidden]="sequence.id !== activeId || !(shouldShowPoster && poster)"
    [src]="poster | atCDN"
    alt=""
  />
</ng-container>
<dwerk-loading-spinner *ngIf="shouldShowSpinner"></dwerk-loading-spinner>
