import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { SessionState, SessionStore } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  project$ = this.select('project').pipe(
    filter((project) => !!(project && project.id))
  );
  projectButtonInfos$ = this.select(
    (state) => state.project && state.project.fileInfos
  );
  projectInfoTexts$ = this.select(
    (state) => state.project && state.project.infoTexts
  );
  themeInfoTexts$ = this.select(
    (state) => state.project && state.themeInfoTexts
  );

  agent$ = this.select('agent').pipe(filter((agent) => !!(agent && agent.id)));

  consultancyData$ = this.select('consultancyData').pipe(
    filter((consultancy) => !!consultancy)
  );

  screenHistory$ = this.select('screenHistory');

  lastScreenId$ = this.screenHistory$.pipe(
    map((history) => history[history.length - 1])
  );

  urlParameters$ = this.select('urlParameters');

  constructor(protected store: SessionStore) {
    super(store);
  }
}
