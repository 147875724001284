<div mat-dialog-content>
    <p>Bitte geben Sie erneut Ihren persönlichen Freischaltcode ein, um die Beratung fortzusetzen.</p>
    <mat-form-field class="full-width">
        <mat-label for="key" id="keyLabel">Freischaltcode</mat-label>
        <input matInput id="key" type="text" [(ngModel)]="data.key" aria-labelledby="keyLabel" cdkFocusInitial />
        <mat-icon matPrefix>lock</mat-icon>
    </mat-form-field>
    <span class="reenter-key-dialog-error" *ngIf="data.showError">Der eingegebene Freischaltcode war nicht korrekt.</span>
</div>
<div mat-dialog-actions>
    <button [mat-dialog-close]="data.key" mat-button>
        Fortfahren
    </button>
</div>