import { Injectable } from '@angular/core';
import { Log } from '@app/shared/constants/app-constants';
import {
  ApplicationInsights,
  IConfig,
  IConfiguration,
} from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { Message, ProjectMessagePayLoad } from '../models/messages';
import { MessagingService } from './messaging.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private config: IConfiguration & IConfig = {
    instrumentationKey: environment.applicationInsightsKey,
    accountId: '',
  };
  private appInsights!: ApplicationInsights;
  private appInsightsActionsQueue: ((
    appInsights: ApplicationInsights
  ) => void)[] = [];

  constructor(private messagingService: MessagingService) {
    this.messagingService.on<ProjectMessagePayLoad>(
      Message.ProjectMessage,
      payload => this.init(payload.id)
    );
  }

  private init(projectId: string) {
    this.config.accountId = projectId;
    this.appInsights = new ApplicationInsights({ config: this.config });
    this.appInsights.loadAppInsights();
    Log.debug('application insights ready.');

    this.appInsightsActionsQueue.forEach(storedAction =>
      storedAction(this.appInsights)
    );
  }

  appInsightDo(action: (appInsights: ApplicationInsights) => void) {
    if (this.appInsights) {
      action(this.appInsights);
    } else {
      this.appInsightsActionsQueue.push(action);
    }
  }
  logEvent(
    name: string,
    properties?: Record<string, string>,
    measurements?: Record<string, number>
  ) {
    this.appInsightDo(appInsights =>
      appInsights.trackEvent({ name, properties, measurements })
    );
  }

  startLogEvent(name: string) {
    this.appInsightDo(appInsights => appInsights.startTrackEvent(name));
  }

  endLogEvent(
    name: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ) {
    this.appInsightDo(appInsights =>
      appInsights.stopTrackEvent(name, properties, measurements)
    );
  }

  logException(
    exception: Error,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ) {
    if (!this.appInsights) {
      this.init('');
    }
    this.appInsights.trackException({ exception, properties, measurements });
  }

  logDependency(
    target: string,
    responseCode: number,
    type: string,
    id: string
  ) {
    this.appInsights.trackDependencyData({ target, responseCode, type, id });
  }
}
