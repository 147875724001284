<div
  [class.fade-out]="shouldHide"
  style="height: 100vh;
          width: 100vw;"
  class="container"
>
  <div class="text mat-body fade-out" [class.fade-out]="shouldHideText">
    {{ text }}
  </div>
</div>
