import { Injectable } from '@angular/core';
import { ScreenCommunicationService } from '@app/modules/storyboard/services/screen-communication.service';
import { Message } from '@app/shared/models/messages';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { ConsultancyService } from '../state/consultancy.service';

const confirmString = `Ihre Beratung ist noch nicht beendet. Wenn Sie die
Seite verlassen wird Ihre Beratung abgebrochen. Wollen Sie dennoch fortfahren?`;

@Injectable({
  providedIn: 'root',
})
export class UnloadService {
  private hasErrorOccured = false;
  private getConsutancyData: () => any;
  constructor(
    private consultancyService: ConsultancyService,
    private messagingService: MessagingService,
    private screenCommunicationService: ScreenCommunicationService
  ) {
    this.getConsutancyData = () =>
      screenCommunicationService.getConsultancyData();

    this.messagingService.on<boolean>(Message.HasErrorMessage, (hasError) => {
      this.hasErrorOccured = hasError;
    });
  }

  emergencySaveData() {
    this.consultancyService.emergencySave(
      this.getConsutancyData(),
      this.hasErrorOccured
    );
  }
  hasUnsavedData = () => !this.consultancyService.isConsultancyClean();

  addUnloadHandler() {
    var us = this;
    document.addEventListener('visibilitychange', function logData() {
      if (document.visibilityState === 'hidden') {
        if ((us.screenCommunicationService.hasStarted && sessionStorage.length) || us.hasErrorOccured) {
          us.emergencySaveData();
        }
      }
    });
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (
        !this.hasErrorOccured &&
        this.screenCommunicationService.hasStarted &&
        sessionStorage.length
      ) {
        //this.emergencySaveData();
        e.returnValue = confirmString;
        e.preventDefault();
        return confirmString;
      }

      // if (this.hasErrorOccured) {
      //   this.emergencySaveData();
      // }
    };
  }
}
