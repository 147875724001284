import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'dwerk-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.styl'],
})
export class SpinnerComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void { }
}
