import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FooterInfosComponent } from '@app/layouts/footer-infos/footer-infos.component';

@Component({
  selector: 'dwerk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input()
  title = '';

  @Input()
  text = '';

  @Input()
  debugActions: any = {
    test: () => {
      console.log('test');
    },
  };

  @Input()
  showDebug = false;

  selectedAction: string = '';

  parameters: string = '';

  @Output()
  refreshActionsEvent = new EventEmitter();

  year = new Date().getFullYear();
  constructor(public matBottomSheet: MatBottomSheet) {}

  ngOnInit(): void {}

  getInfoText(identifier: string) {
    const component =
      FooterInfosComponent as any as ComponentRef<FooterInfosComponent>;

    const bottomSheetRef = (this.matBottomSheet as any).open(component, {
      data: { id: identifier },
    });
  }

  onRefreshActionsClicked(): void {
    this.refreshActionsEvent.emit();
  }

  onExecuteActionClicked(): void {
    let selectedAction = this.debugActions[this.selectedAction];
    let params = this.parameters.split(',').map((p) => p.trim());
    console.log(selectedAction, selectedAction?.length, params);
    if (!selectedAction || !(typeof selectedAction === 'function')) return;

    if (selectedAction.length === 0) {
      console.log('Executing ' + selectedAction.name);
      selectedAction();
    } else {
      console.log(
        'Executing ' + selectedAction.name + ' with params',
        ...params
      );
      let returnvalue = selectedAction(...params);
      if (returnvalue && typeof returnvalue === 'function') {
        console.log('Executing higher order function');
        returnvalue();
      }
    }
  }
}
