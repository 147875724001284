import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { InfoTexts } from '@app/shared/state/models/data/infoTexts.model';
import { SessionQuery } from '@app/shared/state/session.query';
import { Observable } from 'rxjs';

@Component({
  selector: 'dwerk-footer-infos',
  templateUrl: './footer-infos.component.html',
  styleUrls: ['./footer-infos.component.scss'],
})
export class FooterInfosComponent implements OnInit {
  projectInfoTexts$?: Observable<InfoTexts>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { id: string },
    private bottomSheetRef: MatBottomSheetRef,
    query: SessionQuery
  ) {
    this.projectInfoTexts$ = query.projectInfoTexts$;
  }

  ngOnInit() {
    // this.footerInfosService.get().subscribe({
    //   error(err) {
    //     this.error = err;
    //   }
    //  });
  }
}
