<div class="container text-center">
  <div class="headline">
    <h1 class="mat-h1">unserebav.de unterstützt <br />die folgenden Browser</h1>
    <div class="mat-body">
      <a href="https://www.google.de/chrome/" target="_blank"
        ><img
          alt="Chrome"
          src="assets/img/browsers/chrome.png"
          title="Google Chrome"
      /></a>
      <div class="inner-text">
        Google Chrome
      </div>
    </div>
    <div class="mat-body">
      <a href="https://www.mozilla.org/de/firefox/new/" target="_blank"
        ><img
          alt="Firefox"
          src="assets/img/browsers/firefox.png"
          title="Mozilla Firefox"
      /></a>
      <div class="inner-text">
        Mozilla Firefox
      </div>
    </div>
    <div class="mat-body">
      <a href="https://www.microsoft.com/de-de/edge" target="_blank">
        <img
          alt="Microsoft Edge"
          src="assets/img/browsers/edge.png"
          title="Microsoft Edge"
      /></a>
      <div class="inner-text">
        Microsoft Edge
      </div>
    </div>
    <div class="mat-body">
      <a href="https://www.apple.com/de/safari/" target="_blank"
        ><img
          alt="Safari"
          src="assets/img/browsers/safari.png"
          title="Apple Safari"
      /></a>
      <div class="inner-text">
        Apple Safari
      </div>
    </div>
    <div class="mat-body">
      <span class="underline">
        jeweils in der aktuellen Version unter Windows, Linux, MacOS, iPad und
        Android Tablet.
      </span>
    </div>
  </div>
</div>
<dwerk-common-footer></dwerk-common-footer>
