import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { getOriginWithoutWWW } from '@app/core/functions/locationFunktions';
import { exhaustiveChech } from '@app/core/functions/type-guards';
import { ButtonBarInfoComponent } from '@app/layouts/button-bar-info/button-bar-info.component';
import { DebugInfoComponent } from '@app/layouts/debug-info/debug-info.component';
import { StoryboardControl } from '@app/modules/storyboard/models/storyboard-control';
import { BaseComponent } from '@app/shared/components/base-component';
import { Log } from '@app/shared/constants/app-constants';
import { ErrorType } from '@app/shared/constants/error-types';
import { raiseError$ } from '@app/shared/functions/error-functions';
import { addCdnPath } from '@app/shared/functions/file-function';
import { Events } from '@app/shared/models/events';
import { Message, VideoCommand } from '@app/shared/models/messages';
import { ThemeInfoTexts } from '@app/shared/models/theme.model';
import { MessagingService } from '@app/shared/services/messaging.service';
import { startId } from '@app/shared/state/models/constants';
import {
  FileInfo,
  InfoFile,
  Project,
} from '@app/shared/state/models/data/project.model';
import { Sequence } from '@app/shared/state/models/screens/story.model';
import { SessionQuery } from '@app/shared/state/session.query';
import { SessionService } from '@app/shared/state/session.service';
import { Observable as koObservable } from 'knockout';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsService } from '../../../../shared/services/application-insights.service';
import { Ko } from '../../models/control.model';
import {
  ExternalScreenViewModel,
  ScreenViewModel,
  ScreenViewModelPlain,
} from '../../models/external-function';
import { PlayerControl } from '../../models/player-control';
import { StoryboardService } from '../../services/storyboard.service';
import { AuthService } from './../../../../shared/services/auth.service';
import { ScreenCommunicationService } from './../../services/screen-communication.service';
import { Video, VideoPlayState } from './state/models/video.model';
import { scaleScreenFont } from './video/functions/scale-screen';

const errorHandlingBindingProvider = function (this: any, ko: Ko) {
  const original = new ko.bindingProvider();
  // determine if an element has any bindings
  (this as any).nodeHasBindings = original.nodeHasBindings;
  // return the bindings given a node and the bindingContext
  (this as any).getBindings = (node: any, bindingContext: any) => {
    let result;
    try {
      result = original.getBindings(node, bindingContext);
    } catch (e) {
      // here you will do what you you want to do to show to user
      let message = "";
      if (typeof e === "string") {
        message = e;
      } else if (e instanceof Error) {
        message = e.message
      }
      if (console && console.log) {
        console.log('Error in ko-binding: ' + message);
      }
    }
    return result;
  };
};
@Component({
  selector: 'dwerk-videoplayer',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent extends BaseComponent implements OnInit, OnDestroy {
  // #region Properties (23)

  private _hasReachedEnd = false;
  private _hasStarted = false;
  private _isPlaying = false;
  private bigButtonTimeoutToken?: any;
  private ko: Ko;
  private project$: Observable<Project>;
  private screenScript!: HTMLScriptElement;
  private screenTemplate!: HTMLScriptElement;

  dynamicScreen!: SafeHtml;
  file1?: InfoFile;
  file2?: InfoFile;
  fileInfos$?: Observable<FileInfo>;
  showAppointment = true;
  appointmentUrl?: String;
  showPathadvice = true;
  pathAdviceId?: String;
  videoLanguage?: string;
  debugMode = false;
  @Output() public fullscreenChange = new EventEmitter<boolean>();
  public isFullscreen = false;
  isLoading$?: Observable<boolean>;
  public isPlayClicked = false;
  playerControl!: PlayerControl;
  screenVM!: koObservable<ScreenViewModel>;
  public shouldShowBigPlayButton = false;
  public showComponent = false;
  public hasExtendedButtonBar = environment.hasExtendedButtonBar;

  @ViewChild('storyboard', { static: true }) storyboardRef!: ElementRef;
  themeInfo$: Observable<ThemeInfoTexts>;
  video$?: Observable<Video[]>;
  debugActions = {};

  // #endregion Properties (23)

  // #region Constructors (1)

  constructor(
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private query: SessionQuery,
    private dialog: MatDialog,
    private storyboardService: StoryboardService,
    private hostElement: ElementRef<HTMLElement>,
    screenCommunicationService: ScreenCommunicationService,
    private appInsightsService: ApplicationInsightsService,
    private authService: AuthService,
    private zone: NgZone
  ) {
    super();
    this.prepareButtonBar(query);
    this.project$ = this.query.project$;
    this.themeInfo$ = query.themeInfoTexts$;

    //Subscribe Bookings/Pathadvice
    this.project$.pipe(filter((x) => !!x)).subscribe((project) => {
      this.showAppointment = project.projectInfo.masterData.showAppointment ? project.projectInfo.masterData.showAppointment : false;
      this.appointmentUrl = project.projectInfo.masterData.appointmentUrl ? project.projectInfo.masterData.appointmentUrl : "";

      this.showPathadvice = project.projectInfo.masterData.showPathadvice ? project.projectInfo.masterData.showPathadvice : false;
      this.pathAdviceId = project.projectInfo.masterData.pathAdviceId ? project.projectInfo.masterData.pathAdviceId : "";
    });

    this.ko = screenCommunicationService.ko;
  }

  // #endregion Constructors (1)

  // #region Public Accessors (6)

  public get hasReachedEnd() {
    return this._hasReachedEnd;
  }

  public set hasReachedEnd(value) {
    if (value !== this._hasReachedEnd) {
      this._hasReachedEnd = value;
      this.broadcastVideoPlayState();
    } else {
      this._hasReachedEnd = value;
    }
  }

  public get hasStarted() {
    return this._hasStarted;
  }

  public set hasStarted(value) {
    if (value !== this._hasStarted) {
      this._hasStarted = value;
      this.broadcastVideoPlayState();
    } else {
      this._hasStarted = value;
    }
  }

  public get isPlaying() {
    return this._isPlaying;
  }

  public set isPlaying(value) {
    if (value !== this._isPlaying) {
      this._isPlaying = value;
      this.broadcastVideoPlayState();
    } else {
      this._isPlaying = value;
    }
  }

  // #endregion Public Accessors (6)

  // #region Public Methods (23)

  public clearScreen() {
    this.screenTemplate.innerHTML = '';
  }

  public executeVideoCommand(videoCommand: VideoCommand): void {
    switch (videoCommand) {
      case VideoCommand.fullscreenOn:
        this.handleFullscreenChange(true);
        break;

      case VideoCommand.fullscreenOff:
        this.handleFullscreenChange(false);
        break;

      case VideoCommand.play:
        this.playVideo();
        break;

      case VideoCommand.pause:
        this.pauseVideo();
        break;

      case VideoCommand.soundOn:
        this.handleSoundChange(true);
        break;

      case VideoCommand.soundOff:
        this.handleSoundChange(false);
        break;

      default:
        exhaustiveChech(videoCommand);
        break;
    }
  }

  public getFilePath(fileInfo: InfoFile) {
    if (fileInfo) {
      return fileInfo.path;
    }
  }

  public getFileTooltip(fileInfo: InfoFile) {
    if (fileInfo) {
      return fileInfo.tooltip;
    }
  }

  public handleFullscreenChange(isFullscreen: boolean) {
    this.isFullscreen = isFullscreen;
    this.messagingService.broadcast(Message.FullscreenMessage, isFullscreen);

    scaleScreenFont(true);
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

  public ngOnInit() {
    const url = getOriginWithoutWWW(window.origin);
    const tokenPayload = this.authService.tokenPayload;
    const projectId = (tokenPayload && tokenPayload.projectIdentifier) || '';
    const userId = (tokenPayload && tokenPayload.userId) || '';
    const agentId = this.route.snapshot.queryParams.agentId || '';

    // console.log("Query Params:");
    // console.log(this.route.snapshot.queryParams);
    // console.log("TokenPayload");
    // console.log(tokenPayload);

    this.sessionService.updateUrlParameters(this.route.snapshot.queryParams);

    // console.log("Query:");
    // console.log(this.query);

    this.messagingService.broadcast(Message.VideoInitialized);

    this.subscribe(this.project$, (project) => {

      //Set Agent Contact
      let contactId = project.projectInfo.agentContactId || this.route.snapshot.queryParams.contactId || "";
      if(project.agent?.contacts && contactId != "" && (project.agent.contacts.some(c => c.id == contactId || c.contactIdentifier == contactId))) {
        let contact = project.agent.contacts.find(c => c.id == contactId || c.contactIdentifier == contactId);
        if(contact != undefined) {
          project.agent.primaryContact = contact;
        }
      }

      this.onProjectLoaded(project);
      this.pathAdviceId = project.projectInfo.masterData.pathAdviceId ? project.projectInfo.masterData.pathAdviceId : "";

      if (this.showPathadvice) {
        let scripttext = `
        (function(d,s){
          var l=d.createElement(s),e=d.getElementsByTagName(s)[0];
          l.async=true;l.type='text/javascript';
          l.src='https://app.pathadvice.ai/embed.js';
          l.id='` + this.pathAdviceId + `';
          e.parentNode.insertBefore(l,e);})(document,'script');
          `;
        // console.log("PA-Script:", scripttext);
        this.loadScript$(scripttext); //"2NjnDggAawhZ5EKBECOc"
      }
    });

    this.subscribeToMessages();

    this.initStoryboard();
    this.loadProjectData$(url, projectId, agentId, userId);

    // workaround: give splashscreen time to show up before showing video component
    setTimeout(() => {
      this.showComponent = true;
    });
  }

  public onButtonBarClick(identifier: string) {
    this.dialog.open(ButtonBarInfoComponent, {
      data: { id: identifier },
    });
  }

  public onDebugClick() {
    this.dialog.open(DebugInfoComponent, {
      data: this.storyboardService.getDebugInfo(),
    });
  }

  public onRefreshActions() {
    this.debugActions = this.storyboardService.getDebugActions() ?? {};
    console.log(this.debugActions);
  }

  @HostListener('window:keyup', ['$event'])
  public onDebugKey(e: KeyboardEvent) {
    if(e.key == 'd' && e.ctrlKey && e.altKey) {
      this.debugMode = !this.debugMode;
    }
  }

  public onFullscreenClick() {
    this.handleFullscreenChange(true);
  }

  public onPlayClick(shouldStop: boolean) {
    if (shouldStop) {
      this.pauseVideo();
      return;
    }
    this.playVideo();
    this.isPlayClicked = true;
  }

  public onResetScreenClick() {
    this.handleFullscreenChange(false);
  }

  public onSetPlayerControl(control: PlayerControl) {
    this.playerControl = control;

    this.subscribe(control.onTimeUpdate$, ([sequence, time]) =>
      this.onTimeUpdate(sequence, time)
    );
    this.subscribe(
      control.onVideoPlaying$,
      ([seq, playState]: [Sequence, VideoPlayState]) =>
        this.onVideoPlaying(seq, playState)
    );
    this.subscribe(control.onVideoLoaded$, (seq) => this.onVideoLoaded(seq.id));

    this.storyboardService.setControl(createVideoControl(this, this.zone));
  }

  public async onTimeUpdate(currSequence: Sequence, currentTime: number) {
    Log.debug('**************************');
    Log.debug(currentTime);

    if (currentTime) {
      this.hasReachedEnd = currentTime >= currSequence.end - 0.1;
      await this.storyboardService.updateSequence(currentTime, currSequence);
      this.resetBigPlayButton();
    }
    Log.debug('**************************');
  }

  public onVideoLoaded(videoId: string) {
    if (videoId === this.screenVM().id()) {
      this.setBigPlaykButtonTimeout();
    }
  }

  public onVideoPlaying(
    currSequence: Sequence,
    playState: VideoPlayState
  ): void {
    clearTimeout(this.bigButtonTimeoutToken);
    clearInterval(this.bigButtonTimeoutToken);

    if (!this.hasStarted) {
      scaleScreenFont(true);
    }

    this.hasStarted = true;
    this.isPlaying = playState === VideoPlayState.Playing;
    this.hasReachedEnd = playState === VideoPlayState.Ended;

    if (playState === VideoPlayState.Ended) {
      this.storyboardService.updateSequence(Infinity, currSequence);
    }
  }
  public pauseVideo() {
    this.playerControl.pause();
    // this.shouldShowBigPlayButton = true;
    this.isPlayClicked = false;
  }

  public async playVideo(newId?: string, shouldVideoStartAgain?: boolean) {
    this.setBigPlaykButtonTimeout();

    await this.playerControl.play({
      id: newId,
      shouldStartAgain: shouldVideoStartAgain,
    });
  }

  public setBigPlaykButtonTimeout() {
    if (!this.bigButtonTimeoutToken) {
      this.shouldShowBigPlayButton = false;
      this.bigButtonTimeoutToken = setInterval(() => {
        this.shouldShowBigPlayButton = true;
        this.changeDetector.markForCheck();

        this.messagingService.broadcast(
          Message.VideoPlayStateChanged,
          undefined
        );
        clearInterval(this.bigButtonTimeoutToken);
      }, 1000);
    }
  }

  public setDynamicScreenHtml(screenVM: ScreenViewModelPlain) {
    const ko = this.ko;
    const id = `screen #${screenVM.id}#`;
    const html = screenVM.template;

    this.screenTemplate.innerHTML = html;
    this.screenTemplate.id = id;

    this.screenVM({
      id: ko.observable(id),
      data: ko.observable(screenVM.data),
      template: html,
    });
  }

  public setDynamicalVideo(sequenceId: string) {
    this.playerControl.activeId = sequenceId;
  }

  public stopVideo() {
    this.playerControl.pause();
    // this.isPlayClicked = false;
    this.changeDetector.markForCheck();
  }

  public subscribeToMessages() {
    this.addSubscription(
      this.messagingService.on<VideoCommand>(
        Message.EcxecuteVideoCommmand,
        (videoCommand) => this.executeVideoCommand(videoCommand)
      )[1]
    );
  }

  // #endregion Public Methods (23)

  // #region Private Methods (10)

  private applyBindings() {
    const ko = this.ko;
    const storyboardNode = this.storyboardRef.nativeElement;

    try {
      (ko as any).punches.enableAll();
      ko.applyBindings(this.screenVM, storyboardNode);
    } catch (error) {
      let message = "";
      if (typeof error === "string") {
        message = error;
      } else if (error instanceof Error) {
        message = error.message
      }
      console.error(
        'Knockout error occured: ' + message,
        error
      );
    }
  }

  private broadcastVideoPlayState() {
    const newPlayState = {
      hasReachedEnd: this.hasReachedEnd,
      hasStarted: this.hasStarted,
      isPlaying: this.isPlaying,
    };
    this.messagingService.broadcast(
      Message.VideoPlayStateChanged,
      newPlayState
    );

    return newPlayState;
  }

  private handleSoundChange(isSomdOn: boolean) {
    if (this.playerControl) {
      this.playerControl.isSoundOn = isSomdOn;
    }
    // this.messagingService.broadcast(Message.VideoSoundChange, isSomdOn);
  }

  private initScreenVm() {
    const ko = this.ko;
    const emptyTemplate = '<h1>Gleich gehts los!</h1>';
    const initialScreenVm = ko.observable({
      id: ko.observable(startId),
      template: emptyTemplate,
      data: ko.observable({ model: { urlParameters: this.route.snapshot.queryParams } } as ExternalScreenViewModel), //
    } as ScreenViewModel);
    this.screenVM = initialScreenVm;
  }

  private initStoryboard() {
    this.initScreenVm();
    this.initStoryboardScreen();
    this.applyBindings();

    scaleScreenFont(true);
    this.appInsightsService.logEvent(Events.initStoryboard);
  }

  private initStoryboardScreen() {
    const scriptElement = document.createElement('script');
    const templateElement = document.createElement('script');
    templateElement.type = 'text/html';

    this.screenScript = this.hostElement.nativeElement.appendChild(
      scriptElement
    );
    this.screenTemplate = this.hostElement.nativeElement.appendChild(
      templateElement
    );

    templateElement.innerHTML = this.screenVM().template;
    templateElement.id = this.screenVM().id();
  }

  loadScript$(script: string) {
    if (this.screenScript) {
      this.hostElement.nativeElement.removeChild(this.screenScript);
    }
    const loadScript$ = new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.textContent = script;
      scriptElement.onload = () => resolve();
      scriptElement.onerror = (err) => {
        raiseError$({
          message: err && err.toString(),
          errorType: ErrorType.PrepareScreenError,
        });
        reject(err);
      };
      this.hostElement.nativeElement.appendChild(scriptElement);
      this.screenScript = scriptElement;

      // fallback if onload doesnt fire
      setTimeout(() => {
        resolve();
      }, 100);
    });

    return loadScript$;
  }

  private async loadProjectData$(
    url: string,
    id1: string,
    id2: string,
    userId: string
  ) {
    await this.sessionService.loadProjectData$(url, id1, id2, userId);
  }

  private async onProjectLoaded(project: Project) {
    const tokenPayload = this.authService.tokenPayload;

    const firstSequence = await this.storyboardService.initializeStoryBoard$(
      project
    );
    const family = this.storyboardService.getNeighbours(firstSequence);
    this.playerControl.setSequenceData(family, firstSequence.id);
  }

  private prepareButtonBar(query: SessionQuery) {
    this.fileInfos$ = query.projectButtonInfos$;
    this.fileInfos$.pipe(filter((x) => !!x)).subscribe((info) => {
      this.file1 = info.file1 ? { ...info.file1 } : undefined;
      this.file2 = info.file2 ? { ...info.file2 } : undefined;
      if (this.file1 && this.file1.path) {
        this.file1.path = addCdnPath(this.file1.path);
      }
      if (this.file2 && this.file2.path) {
        this.file2.path = addCdnPath(this.file2.path);
      }
    });
  }

  private resetBigPlayButton() {
    clearTimeout(this.bigButtonTimeoutToken);
    this.bigButtonTimeoutToken = null;

    this.shouldShowBigPlayButton = false;
  }

  // #endregion Private Methods (10)
}

function createVideoControl(
  videoComponent: VideoComponent,
  zone: NgZone
): StoryboardControl {
  const playerControl = videoComponent.playerControl;
  return {
    setCurrentTime: (time: number) => playerControl.setNewPlayingTime(time),
    setScreenHtml: (screenVM: ScreenViewModelPlain) =>
      videoComponent.setDynamicScreenHtml(screenVM),
    setScreenScript$: (screenScript: string) =>
      videoComponent.loadScript$(screenScript),
    clearScreen: () => videoComponent.clearScreen(),
    play: (newId?: string, shouldVideoStartAgain?: boolean) => {
      videoComponent.onVideoLoaded(newId || startId);
      return videoComponent.playVideo(newId, shouldVideoStartAgain ?? true);
    },
    stop: () => {
      videoComponent.pauseVideo();
    },
    setSequenceDataForPlayer: (sequences: Sequence[], activeId: string) =>
      playerControl.setSequenceData(sequences, activeId),
    raiseError: (errorType: ErrorType, message?: string) =>
      zone.run(() => raiseError$({ errorType, message })),
    setStoryVideo: (sequenceId: string) =>
      videoComponent.setDynamicalVideo(sequenceId),
    toFullscreen: (isFullscreen?: boolean) => {
      videoComponent.handleFullscreenChange(!!isFullscreen);
    },
    setLanguage: (lang: string) => {
      videoComponent.videoLanguage = lang;
    }
  };
}
