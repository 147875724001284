import { Pipe, PipeTransform } from '@angular/core';
import { urlJoin } from '@app/core/functions/string-functions';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'atCDN'
})
export class AtCdnPipe implements PipeTransform {
  constructor() {}

  transform(value: string) {
    if (value) {
      const cdnPath = environment.projectCdnBaseUri;
      const fullPath = urlJoin(cdnPath, value);

      return fullPath;
    }

    return value;
  }
}
