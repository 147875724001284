import { Component, Inject, OnInit } from '@angular/core';
import { InfoTexts } from '@app/shared/state/models/data/infoTexts.model';
import { SessionQuery } from '@app/shared/state/session.query';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sequence } from '@app/shared/state/models/screens/story.model';
import { ConsultancyData } from '@app/shared/state/models/data/consultancy.model';
import { ProjectInfo } from '@app/shared/state/models/data/projectInfo.model';

@Component({
  selector: 'dwerk-debug-info',
  templateUrl: './debug-info.component.html',
  styleUrls: ['./debug-info.component.css'],
})
export class DebugInfoComponent implements OnInit {
  projectInfoString = "{}";
  screenString = "{}";
  consultancyDataString = "{}";
  viewModelString = "{}";
  historyString = "[]";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { projectInfo: ProjectInfo, screen: Sequence, consultancyData: ConsultancyData, vm: Object, history: string[] },
    query: SessionQuery
  ) {
    console.log("Data", data);
    this.projectInfoString = JSON.stringify(data.projectInfo, null, 2);
    this.screenString = JSON.stringify(data.screen, null, 2);
    this.consultancyDataString = JSON.stringify(data.consultancyData, null, 2);
    this.viewModelString = JSON.stringify(data.vm, null, 2);
    this.historyString = JSON.stringify(data.history, null, 2);
  }

  ngOnInit() {
    // this.footerInfosService.get().subscribe({
    //   error(err) {
    //     this.error = err;
    //   }
    //  });
  }
}
