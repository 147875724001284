import { Injectable } from '@angular/core';
import { toMaterialPalette } from '@app/core/functions/color-functions';
import { camelToKebapCase } from '@app/core/functions/string-functions';
import { MaterialPalette } from '@app/core/models/color-models';
import { of } from '@app/core/true-myth-monads/maybe';
import { ok } from '@app/core/true-myth-monads/result';
import { environment } from 'src/environments/environment';
import {
  ThemeBasicColors,
  ThemeInfo,
  ThemeMiscColors,
} from '../models/theme.model';

const urlJoin = require('url-join') as (...parts: string[]) => string;
const cdnUri = environment.projectCdnBaseUri;

@Injectable({
  providedIn: 'root',
})
export class ThemeCreationService {
  themeInfo?: ThemeInfo;
  constructor() {}

  loadThemeInUI(themeInfo: ThemeInfo, logoPath: string) {
    this.themeInfo = themeInfo || {};
    const { otherColors, backgroundUri, favIconUri } = themeInfo;

    const palette = this.processBasicColors(themeInfo as ThemeBasicColors);

    of(otherColors).ifJust((colors) =>
      this.processOtherColors(colors, palette)
    );
    this.setTheme(palette);

    this.setAssets(backgroundUri, favIconUri, logoPath);
  }
  setAssets(backgroundUri?: string, favIconUri?: string, logoPath?: string) {
    const logoImg = of(document.getElementById('logo-Img') as HTMLImageElement);

    logoImg.ifJust((img) => {
      if (logoPath) {
        img.src = urlJoin(environment.projectCdnBaseUri, logoPath || '');
      }
    });

    of(backgroundUri).ifJust((img) => {
      if (img) {
        document.body.style.backgroundImage = `url('${urlJoin(
          environment.projectCdnBaseUri,
          img
        )}')`;
      }
    });

    of(favIconUri).ifJust((uri: string) => {
      if (uri) {
        setFavicon(uri);
      }
    });
  }
  private setTheme(theme: any) {
    Object.keys(theme).forEach((key) =>
      document.documentElement.style.setProperty(key, theme[key])
    );
  }

  private processOtherColors(
    otherColors: ThemeMiscColors | undefined,
    palette: any
  ) {
    if (otherColors) {
      const entries = Object.entries(otherColors);
      for (const entry of entries) {
        const newKey = `--plattform-${camelToKebapCase(entry[0])}`;
        palette[newKey] = entry[1];
      }
    }
  }

  private processBasicColors(basicColors: ThemeBasicColors) {
    let palette = {} as any;
    palette = addPalette('primary', basicColors, palette);
    palette = addPalette('accent', basicColors, palette);
    palette = addPalette('warn', basicColors, palette);

    palette['--color-primary'] = basicColors.primary;
    palette['--color-accent'] = basicColors.accent;
    palette['--color-warn'] = basicColors.warn;
    palette['--color-background'] =
      basicColors.background || basicColors.primary;
    palette['--color-background-2'] =
      basicColors.background2 || basicColors.accent;

    if (basicColors.text) {
      palette['--color-text'] = basicColors.text;
    }
    if (basicColors.text2) {
      palette['--color-text-2'] = basicColors.text2;
    }
    palette['--color-text-video'] = basicColors.textVideo
      ? basicColors.textVideo
      : basicColors.text2;
    palette['--color-progress-active'] = basicColors.progressbarActive;
    palette['--color-progress-inactive'] = basicColors.progressbarInactive;
    return palette;
  }
}

function addPalette(key: string, colors: ThemeBasicColors, palettes: any) {
  const colorData = ok((colors as any)[key]);
  // const color = (colors as any)[key];

  const result = colorData.map((color: string) => {
    const name = '--platform-' + key;
    const matPalette = toMaterialPalette(color);
    const palette = fixColorNames(matPalette, name);

    return { ...palettes, ...palette };
  });

  return result.unwrapOr(palettes);

  function fixColorNames(matPalette: MaterialPalette, prefix: string) {
    const palette = {} as any;
    const entries = Object.entries(matPalette);
    for (const entry of entries) {
      const newKey = `${prefix}-${entry[0]}`;
      palette[newKey] = entry[1];
    }
    return palette;
  }
}

function renameProp(oldProp: string, newProp: string, obj: any) {
  const { [oldProp]: value, ...others } = obj;
  return { [newProp]: value, ...others };
}

function setFavicon(favIconUri: string) {
  const link = (document.querySelector(`link[rel*='icon']`) ||
    document.createElement('link')) as HTMLLinkElement;
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = `${urlJoin(environment.projectCdnBaseUri, favIconUri)}`;
  document.getElementsByTagName('head')[0].appendChild(link);
}
