import { Injectable } from '@angular/core';
import {
  ExternalScreenFunction,
  ExternalScreenViewModel,
} from '@app/modules/storyboard/models/external-function';
import { SequenceControlObject } from '@app/modules/storyboard/models/sequence-control-object.model';
import { raiseError$ } from '@app/shared/functions/error-functions';
import { SessionService } from '@app/shared/state/session.service';
import { ErrorType } from '../constants/error-types';
import { StoryLibs } from '../state/models/screens/storyBoard.model';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  constructor(private sessionService: SessionService) {}

  loadStoryBoardScript$ = (scriptName: string) =>
    this.sessionService.loadScriptFromCDN$(scriptName);

  loadStoryScript = (scriptContent: string) => this.sessionService.loadStoryScript(scriptContent);

  loadLibsFromCdn$ = (libs: StoryLibs) => this.sessionService.loadLibsFromCdn$(libs);

  // vg: todo - better way?!
  callStoryBoardFunction(
    funcNameWithNamespace: string,
    params: SequenceControlObject
  ): ExternalScreenViewModel {
    try {
      const functionToExecute = this.getFunctionFromContext(
        funcNameWithNamespace
      );
      return functionToExecute(params);
    } catch (err) {
      raiseError$({
        errorType: ErrorType.VideoRunError,
        message: `Javascript function "${funcNameWithNamespace}" not found`,
      });
      throw new Error(
        `Function ${funcNameWithNamespace} could not be excecuted: ${err}`
      );
    }
  }

  private seperateNamespacesInFunctionName = (functionName: string) =>
    functionName.split('.');

  private getFunctionFromContext(functionName: string) {
    const namesParts = this.seperateNamespacesInFunctionName(functionName);
    let context: any = window;
    namesParts.forEach(
      part => (context = this.mapFunctionNameToFunction(part, context))
    );

    return context as ExternalScreenFunction;
  }

  private mapFunctionNameToFunction(property: string, context: any) {
    if (property in context) {
      return context[property];
    }
    throw Error(`property with name ${property} not provided in Context!`);
  }
}
