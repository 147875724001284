import { Router } from '@angular/router';
import { ErrorType } from '@app/shared/constants/error-types';
import { Message } from '@app/shared/models/messages';
import { MessagingService } from '@app/shared/services/messaging.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

let messagingService!: MessagingService;
let router: Router;
let hasErrorOccured = false;

const allowedMessages = [
  'NotAllowedError: play() failed because the user did not interact with the document first',
];
export function init(service: MessagingService, routerObj: Router) {
  messagingService = service;
  router = routerObj;
}

export async function raiseError$({
  errorType,
  message,
}: {
  errorType: ErrorType;
  message?: string;
}) {
  const isAllowed = allowedMessages.some(mess =>
    (message || '').includes(mess)
  );

  console.error(`${message || ''} (${errorType})`);

  if (hasErrorOccured || isAllowed) {
    return;
  }

  await router.navigate([`/error/${errorType}`], { skipLocationChange: true });

  hasErrorOccured = true;
  messagingService.broadcast(Message.HasErrorMessage, true);
}

export function handleError<T>(data$: Observable<T>) {
  return data$.pipe(
    catchError((error, _) => {
      raiseError$({
        errorType: ErrorType.Unexpected,
        message: error && error.message,
      });
      return throwError(error);
    })
  );
}
