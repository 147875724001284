import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { exhaustiveChech } from '@app/core/functions/type-guards';

@Pipe({
  name: 'sanitize'
})
export class Sanitizer implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, type: 'base64' | 'html') {
    switch (type) {
      case 'base64':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);

      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
    exhaustiveChech(type);
  }
}
