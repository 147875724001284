import { isNull, isUndefined } from 'util';

export const isNil = (obj: any): obj is null | undefined =>
  isUndefined(obj) || isNull(obj);

export function is<T>(data: T | undefined): data is T {
  return data !== undefined;
}
export function exhaustiveChech(data: never): never {
  throw new Error('This should not happen.');
}
