import { ErrorHandler, Injectable } from '@angular/core';
import { Log } from '@app/shared/constants/app-constants';
import { ErrorType } from '@app/shared/constants/error-types';
import { raiseError$ } from '../functions/error-functions';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class PortalErrorHandler implements ErrorHandler {
  constructor(private appInsights: ApplicationInsightsService) {}
  handleError(error: Error) {
    this.appInsights.logException(error);
    Log.error(error);
    raiseError$({
      errorType: ErrorType.Unexpected,
      message: error && error.message,
    });
  }
}
