import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ErrorMessages,
  errorTitle,
} from '@app/shared/constants/error-messages';
import { ErrorType } from '@app/shared/constants/error-types';
import { Events } from '@app/shared/models/events';
import { Message } from '@app/shared/models/messages';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { ApplicationInsightsService } from '../../services/application-insights.service';
import { BaseComponent } from '../base-component';

const ErrorPageSelector = 'dwerk-error-page';

@Component({
  selector: ErrorPageSelector,
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent extends BaseComponent
  implements OnInit, OnDestroy {
  errorText = '';
  titleText = '';

  constructor(
    private route: ActivatedRoute,
    private messagingService: MessagingService,
    private appInsightsService: ApplicationInsightsService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.route.paramMap, params => {
      const type = params.get('type') || undefined;
      this.onActiveRouteChanged(type);
    });

    this.subscribe(this.route.data, data => {
      if (data.type) {
        this.onActiveRouteChanged(data.type);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private onActiveRouteChanged(type: string | undefined) {
    this.setTexts(type);
    this.messagingService.broadcast(Message.HasErrorMessage, true);
    this.messagingService.broadcast(Message.FullscreenMessage, true);

    this.changeDetector.detectChanges();
  }
  private setTexts(type?: string): void {
    this.errorText =
      type && type in ErrorType
        ? ErrorMessages[type as ErrorType]
        : ErrorMessages.Default;
    this.titleText = errorTitle;

    this.appInsightsService.logEvent(Events.errorDisplay, {
      errorText: this.errorText,
    });
  }
}
