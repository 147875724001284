import { AddressData } from './addressData.model';
import { Contact } from './contact.model';

export interface Insurance2AgentInfo {
  agentNumber: string;
  insuranceCompanyId: string;
}
export interface Agent extends AddressData {
  agentInsuranceNumber: string;
  agentIdentifier: string;
  description: string;
  gdvInsurerNumber: string;
  ihkStatus: string;
  companyId: string;
  legalForm: string;
  turnoverTaxId: string;
  localTaxOffice: string;
  commercialRegisterCourt: string;
  ihkRegistryNumber: string;
  timeSpentByMeOnEmailAndMeetings: string;
  companyRegistryNumber: string;
  accountCategoryCode: number;
  primaryContactId: string;
  insurance2AgentInfos: Insurance2AgentInfo[];
  primaryContact: Contact;
  contacts: Contact[];
}

export function createAgent() {
  return {} as Agent;
}
