<!-- <div class="progress-bar-container mat-typography">
  <ul class="progress-bar">
    <li
      *ngFor="let phase of phases"
      [ngStyle]="{ width: itemWidth }"
      [ngClass]="{
        active: phase.isActive,
        finished: phase.isFinished || isFinished
      }"
    >
      {{ phase.title }}
    </li>
  </ul>
</div> -->
<div id="progress-bar-container">
  <button
    type="button"
    *ngIf="isInPlayMode"
    [attr.disabled]="!canPlay ? 'disabled' : null"
    (click)="onPlayClick()"
    mat-icon-button
    class="video-control"
    aria-label="Play"
  >
    <mat-icon>play_arrow</mat-icon>
  </button>
  <button
    type="button"
    *ngIf="!isInPlayMode"
    (click)="onPauseClick()"
    mat-icon-button
    class="video-control"
    aria-label="Pause"
  >
    <mat-icon>pause</mat-icon>
  </button>
  <ul id="progress-bar" [ngStyle]="{ width: progressbarWidth }">
    <li
      *ngFor="let phase of phases"
      class="phase"
      [ngStyle]="{ width: itemWidth }"
      [ngClass]="{
        active: phase.isActive,
        inactive: !phase.isActive,
        finished: phase.isFinished,
        'is-current': phase.isActive && !phase.isFinished
      }"
    >
      <span class="phase-title">{{ phase.title }}</span>
    </li>
  </ul>
  <button
    type="button"
    *ngIf="!isSoundOn"
    (click)="onToggleSound()"
    mat-icon-button
    class="video-control sound"
    aria-label="Sound off"
  >
    <mat-icon>volume_off</mat-icon>
  </button>
  <button
    type="button"
    *ngIf="isSoundOn"
    (click)="onToggleSound()"
    mat-icon-button
    class="video-control sound"
    aria-label="Sound on"
  >
    <mat-icon>volume_up</mat-icon>
  </button>
  <button
    type="button"
    *ngIf="isFullscreen"
    (click)="onToggleFullscreen()"
    mat-icon-button
    class="video-control fullscreen"
    aria-label="exit Fullscreen"
  >
    <mat-icon>fullscreen_exit</mat-icon>
  </button>
  <button
    type="button"
    *ngIf="!isFullscreen"
    (click)="onToggleFullscreen()"
    mat-icon-button
    class="video-control fullscreen"
    aria-label="Fullscreen"
  >
    <mat-icon>fullscreen</mat-icon>
  </button>
</div>
