import { v4 as uuidv4 } from 'uuid';
import { EntityModel } from './entity.model';

interface ConsultancyDataBase {
  documents?: Document[];
  [x: string]: any;
}

export interface ConsultancyKvz extends ConsultancyDataBase {
  type: 'KVZ';
  tariffIds?: string[];
  monthlyRate?: number;
  contractPeriod?: number;
}

export interface ConsultancyBav extends ConsultancyDataBase {
  type: 'BAV';
  employeeData?: EmployeeData;
  tariffId?: string;
  insuranceStartDate?: Date | string; // string as ISO-Format;
  netOwnContribution?: number;
  taxSaving?: number;
  socialInsuranceSavigs?: number;
  employerContribution?: number;
  employerContributionFix?: number;
  employerContributionPercent?: number;
  deferredCompensation?: number;
  totalDeposit?: number;
  guaranteedPension?: number;
  totalPension?: number;
  guaranteedCapital?: number;
  totalCapital?: number;
  monthlyRate?: number;
  offerIdentifier?: string;
}

export interface EmployeeData {
  firstName: string;
  lastName: string;
  salutation: string;
  city: string;
  street: string;
  postalCode: string;
  birthdate?: Date | string; // string as ISO-Format;
  houseNumber: string;
  personnelNumber: string;
  companySince?: Date | string; // string as ISO-Format;
  pensionAge?: number;
}

export interface Document {
  title: string;
  mimeType: string;
  base64Content: string;
}

export type ConsultancyData =
  | ConsultancyKvz
  | ConsultancyBav
  | ConsultancyDataBase;

export interface Consultancy extends EntityModel {
  createdOn: string;
  status: ConsultancyStatus;
  decryptionKeyHash: string;
  projectIdentifier: string;
  projectName: string;
  projectId: string;
  projectType: string;
  userId?: string;
  lastScreen?: string;
  language?: string;
  agentId?: string;
  consultancyData: ConsultancyData;
  documents?: Document[];
}

export enum ConsultancyStatus {
  accepted = 'accepted',
  error = 'error',
  declined = 'declined',
  incomplete = 'incomplete',
}

export enum ContinueStatus
{
    NoConsultancy = 0,
    Closed = 1,
    RequiresKey = 2,
    WrongKey = 3,
    Ok = 4
}

/**
 * A factory function that creates Consultancy
 */
export function createConsultancy(
  {
    status,
    decryptionKeyHash: decrypted,
    projectIdentifier,
    projectId,
    userId,
    projectName,
    projectType,
    agentId,
    consultancyData,
    documents,
    lastScreen,
    language,
  }: {
    status: ConsultancyStatus;
    decryptionKeyHash: string;
    projectIdentifier: string;
    projectId: string;
    userId: string | undefined;
    projectName: string;
    projectType: string;
    agentId?: string;
    lastScreen?: string;
    language?: string;
    consultancyData: ConsultancyData;
    documents?: Document[];
  },
  id?: string
): Consultancy {
  return {
    status,
    decryptionKeyHash: decrypted,
    projectIdentifier,
    projectName,
    projectId,
    projectType,
    userId,
    lastScreen,
    language,
    agentId: agentId || undefined,
    consultancyData,
    id: id || uuidv4(),
    createdOn: new Date().toISOString(),
    documents,
  };
}
