import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dwerk-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  @Input()
  color = '';

  @Input()
  textColor = '';

  @Input()
  backgroundColor = '';

  @Input()
  delayMs = 0;

  showLoading = false;

  constructor() {}

  ngOnInit() {
    const cssVariables = document.documentElement.style;

    setTimeout(() => (this.showLoading = true), this.delayMs);

    if (this.color) {
      cssVariables.setProperty('--spinner-color', this.color);
    }
    if (this.textColor) {
      cssVariables.setProperty('--spinner-text-color', this.textColor);
    }
    if (this.backgroundColor) {
      cssVariables.setProperty(
        '--spinner-background-color',
        this.backgroundColor
      );
    }
  }
}
