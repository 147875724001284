export interface Video {
  path: string;
  updatedAt: string;
  content: string;
}

export enum VideoPlayState {
  Playing = 1,
  Paused = 0,
  Ended = -1
}

/**
 * A factory function that creates Video
 */
export function createVideo(params: Partial<Video>) {
  return {} as Video;
}
