import { Injectable } from '@angular/core';
import { knockout } from '@app/modules/storyboard/models/control.model';
import { ErrorType } from '@app/shared/constants/error-types';
import { raiseError$ } from '@app/shared/functions/error-functions';
import { ConsultancyData } from '@app/shared/state/models/data/consultancy.model';
import { Sequence } from '@app/shared/state/models/screens/story.model';
import { isNil } from '@datorama/akita';

@Injectable({
  providedIn: 'root',
})
export class DecisionProvider {
  public getNextSequence(
    currentSection: Sequence,
    consultancyData?: ConsultancyData
  ) {
    if (currentSection.descendant) {
      return currentSection.descendant;
    }

    if (!(currentSection.descendants && currentSection.test)) {
      return;
    }

    const { descendant, resultKey } = this.executeSectionTest(
      consultancyData,
      currentSection
    );

    if (isNil(descendant)) {
      raiseError$({
        errorType: ErrorType.NotFound,
        message: `No valid descendants found for test result '${resultKey}' in sction '${currentSection.id}'`,
      });
    }

    return descendant;
  }

  private executeSectionTest(consultancyData: any, currentSection: Sequence) {
    if (!consultancyData || !currentSection) {
      return { descendant: null, resultKey: null };
    }

    const plainTestObj = knockout.vm.toJsModel(consultancyData);
    const resultKey = this.exeuteDynamicTest(
      plainTestObj,
      currentSection.test || 'true'
    ) as string;

    const decendantsDict = currentSection.descendants;
    const descendant =
      decendantsDict && (decendantsDict[resultKey] || decendantsDict._);

    return { descendant, resultKey };
  }

  private exeuteDynamicTest(ctx: any, funcBody: string) {
    const commands = [];

    for (const k of Object.keys(ctx)) {
      const command = `var ${k} = ctx.${k};`;
      commands.push(command);
    }
    const res = `return (${funcBody}).toString();`;
    commands.push(res);
    // tslint:disable-next-line: function-constructor
    const F = new Function('ctx', commands.join('\n'));
    return F(ctx);
  }
}
