import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { subscribe } from '@app/core/functions/observable-functions';
import { is } from '@app/core/functions/type-guards';
import { Log } from '@app/shared/constants/app-constants';
import { addCdnPath } from '@app/shared/functions/file-function';
import { Events } from '@app/shared/models/events';
import { MessagingService } from '@app/shared/services/messaging.service';
import { Agent } from '@app/shared/state/models/data/agent.model';
import {
  ConsultancyData,
  ConsultancyStatus,
} from '@app/shared/state/models/data/consultancy.model';
import { Project } from '@app/shared/state/models/data/project.model';
import { ProjectInfoExt } from '@app/shared/state/models/data/projectInfo.model';
import { Sequence } from '@app/shared/state/models/screens/story.model';
import { StoryBoard } from '@app/shared/state/models/screens/storyBoard.model';
import { SessionQuery } from '@app/shared/state/session.query';
import { SessionService } from '@app/shared/state/session.service';
import { WithUntilDestroyed } from '@orchestrator/ngx-until-destroyed';
import { Observable } from 'rxjs';
import { isString } from 'util';
import {
  ConsultancyLoadedPayLoad,
  Message,
  ProgressBarMessagePayload,
  StoryboardStartMessagePayload,
} from '../../../shared/models/messages';
import { ApplicationInsightsService } from '../../../shared/services/application-insights.service';
import { ConsultancyService } from '../../../shared/state/consultancy.service';
import { StoryboardControlExternal } from '../models/control.model';
import { StoryboardControl } from '../models/storyboard-control';
import { VideoControl } from '../models/video-control';
import { ErrorType } from './../../../shared/constants/error-types';
import { DecisionProvider } from './decision-provider';
import { ScreenCommunicationService } from './screen-communication.service';

@Injectable({
  providedIn: 'root',
})
export class StoryboardService implements OnDestroy {
  @WithUntilDestroyed()
  history$: Observable<string[]>;

  @WithUntilDestroyed()
  lastScreenId$: Observable<string>;

  private curSequenceSection!: string;

  constructor(
    private messagingService: MessagingService,
    private screenCommunicationService: ScreenCommunicationService,
    private consultancyService: ConsultancyService,
    private decisionProvider: DecisionProvider,
    private appInsightsService: ApplicationInsightsService,
    private sessionService: SessionService,
    private router: Router,
    query: SessionQuery
  ) {
    this.history$ = query.screenHistory$;
    this.lastScreenId$ = query.lastScreenId$;

    this.messagingService.on<ConsultancyLoadedPayLoad>(
      Message.ConsultancyLoaded,
      (payload) => {
        const lastScreen = payload.lastScreen;
        const language = payload.language;
        const shouldReload = payload.shouldReload;

        if (is<string>(language)) {
          this.consultancyService.currentLanguage = language;
          this.videoControl.setLanguage(language);
        }

        if (is<string>(lastScreen)) {
          this.sessionService.historyClear();
          setTimeout(async () => {
            this.curSequenceSection = lastScreen;
            await this.loadAndSetNextSequences$(lastScreen);
            if(shouldReload) {
              this.restartSection(true);
            }
          });
        }
      }
    );

    this.subscribe();
  }

  private storyBoard!: StoryBoard;
  private hasStoryboaedStarted = false;
  private videoControl!: VideoControl;
  private lastVisitedScreenId!: string;

  setControl(videoControl: StoryboardControl) {
    this.videoControl = this.videoControl || videoControl;
  }

  async initializeStoryBoard$({ id, projectInfo, storyboard, agent }: Project) {
    const rootId = storyboard.root;
    this.curSequenceSection = rootId;
    this.storyBoard = storyboard;

    await this.initScreenCommunicationService$(
      {
        id: id as string,
        ...projectInfo,
      },
      agent
    );

    this.signalStoryboardStart(async () => {
      await this.startSequence$(storyboard.run[this.curSequenceSection]);
    });

    return storyboard.run[this.curSequenceSection];
  }

  private async initScreenCommunicationService$(
    projectInfo: ProjectInfoExt,
    agent: Agent
  ) {
    const storyboardControl = this.createStoryboardControl(projectInfo, agent);
    const videoControl = this.videoControl;
    await this.screenCommunicationService.init$(
      storyboardControl,
      videoControl
    );
  }

  private async playVideo() {
    await this.videoControl.play();
  }

  private async pauseVideo() {
    await this.videoControl.stop();
  }

  private async startSequence$(sequence: Sequence) {
    try {
      await this.screenCommunicationService.setExternalView$(sequence);
      await this.playVideo();

      await this.loadAndSetNextSequences$(sequence.id);
    } catch (err) {
      throw err;
    }
  }

  private async loadAndSetNextSequences$(sequenceId: string) {
    setTimeout(() => {
      const nextSequences = this.getNeighbours(sequenceId);
      this.videoControl.setSequenceDataForPlayer(nextSequences, sequenceId);
    }, 0);
  }

  async updateSequence(newTime: number, _currentSequence: Sequence) {
    const currentSequence = _currentSequence;
    this.screenCommunicationService.updateTimeObservable(
      newTime,
      currentSequence.id
    );
    const hasSequenceFinished = this.hasSequenceReachedEndTime(
      currentSequence,
      newTime
    );
    const shouldContinue = currentSequence.autoTransition;
    const shouldFinishConsultancy =
      !this.consultancyService.isConsultancyClean() &&
      this.hasReachedEndOfConsultancy(currentSequence);

    if (!hasSequenceFinished) {
      Log.debug(
        'waiting - ' +
          'start current sequence:  ' +
          currentSequence.start +
          ' end: ' +
          currentSequence.end
      );
    } else if (shouldContinue) {
      await this.goToNextSection$(currentSequence);
      Log.debug('start next section: ' + currentSequence.descendant);
    } else if (shouldFinishConsultancy) {
      await this.saveConsultancy();
    } else {
      await this.pauseVideo();
      Log.debug('video paused.');

      if (!(currentSequence.descendant || currentSequence.descendants)) {
        this.finishProgressbar();
      }
    }

    return hasSequenceFinished;
  }
  finishProgressbar() {
    this.informProgressBar(undefined, true);
  }

  getNeighbours(seq: Sequence | string) {
    const sequence = isString(seq) ? this.storyBoard.run[seq] : seq;

    const neighbors = new Set<string>();
    neighbors.add(sequence.id);
    neighbors.add(sequence.descendant || '');
    [...Object.values(sequence.descendants || {})].forEach((id) =>
      neighbors.add(id)
    );
    neighbors.add(this.sessionService.historyPeek() || sequence.id);

    return [...neighbors]
      .filter((x) => !!x)
      .map((id) => this.storyBoard.run[id]);
  }

  async restartSection(runVideo: boolean) {
    console.log(this.curSequenceSection);
    await this.startSection$(this.curSequenceSection, runVideo);
  }

  private async goToPreviousSection(currentSection: Sequence) {
    this.appInsightsService.logEvent(Events.jumpToPreviousSection, {
      currentSection: currentSection.id,
    });
    const predecessorId = this.sessionService.historyPop();

    if (predecessorId) {
      await this.startSection$(predecessorId, true);
    }
  }

  private async goToNextSection$(currentSection: Sequence) {
    this.appInsightsService.logEvent(Events.jumpToNextSection, {
      currentSectionId: currentSection.id,
    });
    const currentSectionId = currentSection.id;
    const consultancyData = this.screenCommunicationService.getConsultancyData();
    const nextSequenceId = this.decisionProvider.getNextSequence(
      currentSection,
      consultancyData
    );

    if (nextSequenceId) {
      await this.startSection$(nextSequenceId, true);
      this.sessionService.historyPush(currentSectionId);
    } else {
      this.finishProgressbar();
    }
  }

  private async startSection$(
    sequenceId: string,
    shouldVideoStartAgain?: boolean
  ) {
    this.videoControl.play(sequenceId, shouldVideoStartAgain);
    this.curSequenceSection = sequenceId;
    const nextSequence = this.storyBoard.run[sequenceId];
    Log.debug('up to next sequence! ');

    this.informProgressBar(nextSequence);
    this.consultancyService.currentScreenId = sequenceId;
    await this.startSequence$(nextSequence);
    Log.debug('sequence started!');
  }

  private informProgressBar(
    nextSequence?: Sequence,
    shouldFinish: boolean = false
  ) {
    this.messagingService.broadcast<ProgressBarMessagePayload>(
      Message.ProgressBarProceedMessage,
      {
        nextPhase: nextSequence && nextSequence.phase,
        shouldFinish,
      }
    );
  }

  private signalStoryboardStart(playVideo: () => void) {
    if (!this.hasStoryboaedStarted) {
      this.messagingService.broadcast<StoryboardStartMessagePayload>(
        Message.StoryboardStartMessage,
        { playVideo }
      );
      this.hasStoryboaedStarted = true;
    }
  }

  private hasSequenceReachedEndTime(
    currentSequence: Sequence,
    currentTimeStamp: number
  ): boolean {
    const endTime = currentSequence && currentSequence.end;
    return currentTimeStamp > endTime || currentTimeStamp === Infinity;
  }

  private hasReachedEndOfConsultancy(currentSequence: Sequence): boolean {
    return currentSequence.autoFinish;
  }

  private createStoryboardControl(
    projectInfo: ProjectInfoExt,
    agent: Agent
  ): StoryboardControlExternal {
    return {
      pause: () => this.pauseSection(),
      goTo: (sequenceId, consultancyData: ConsultancyData) =>
        this.jumpToSection(sequenceId, consultancyData),
      goForward: (consultancyData: ConsultancyData) =>
        this.continueSection(consultancyData),
      goBack: (consultancyData: ConsultancyData) =>
        this.jumpToLastSection(consultancyData),
      toFullscreen: (isFullscreen?: boolean) => {
        this.videoControl.toFullscreen(isFullscreen);
      },
      logout: () => {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['logout']);
        setTimeout(() => {
          window.location.reload();
        });
      },
      saveAsync: async (
        consultancyData: ConsultancyData,
        status?: ConsultancyStatus
      ) => {
        await this.saveConsultancy(consultancyData, status);
        await this.continueSection();
      },
      saveAndGoForwardAsync: async (
        consultancyData: ConsultancyData,
        status?: ConsultancyStatus
      ) => {
        await this.saveConsultancy(consultancyData, status);
        await this.continueSection();
      },
      saveAndStayAsync: async (
        consultancyData: ConsultancyData,
        status?: ConsultancyStatus
      ) => {
        await this.saveConsultancy(consultancyData, status);
      },
      reload: (runVideo: boolean) => this.restartSection(runVideo),
      error: (errorType: ErrorType, msg?: string) =>
        this.raiseError(errorType, msg),
      getProjectInfo: () => projectInfo,
      getAgent: () => agent,
      addCdnPath: (path) => addCdnPath(path),
      setLanguage: (lang: string) => {
        this.consultancyService.currentLanguage = lang;
        this.videoControl.setLanguage(lang);
      },
    };
  }
  saveConsultancy = async (
    consultancyData?: ConsultancyData,
    status?: ConsultancyStatus
  ) => {
    if (consultancyData) {
      this.consultancyService.updateConsultancyData(consultancyData);

      await this.consultancyService.saveConsultancyData$(
        consultancyData,
        status
      );
    }

    //await this.continueSection();
  };

  pauseSection = async () => {
    await this.videoControl.stop();
  };

  raiseError = async (errorType: ErrorType, msg?: string) => {
    await this.videoControl.raiseError(errorType, msg);
  };

  continueSection = async (
    consultancyData: ConsultancyData | undefined = undefined
  ) => {
    if (consultancyData) {
      this.consultancyService.updateConsultancyData(consultancyData);
    }

    const currentSection = this.getCurrentSection();
    this.goToNextSection$(currentSection);
  };

  jumpToLastSection = async (
    consultancyData: ConsultancyData | undefined = undefined
  ) => {
    if (consultancyData) {
      this.consultancyService.updateConsultancyData(consultancyData);
    }

    const currentSection = this.getCurrentSection();
    this.goToPreviousSection(currentSection);
  };
  jumpToSection = async (
    sequenceIdentifier: string,
    consultancyData: ConsultancyData | undefined = undefined
  ) => {
    if (consultancyData) {
      this.consultancyService.updateConsultancyData(consultancyData);
    }
    const sequence = Object.values(this.storyBoard.run).find(
      (seq) => seq.label === sequenceIdentifier || seq.id === sequenceIdentifier
    );
    if (sequence) {
      const currentId = this.getCurrentSection().id
      this.sessionService.historyPush(currentId);
      this.videoControl.setSequenceDataForPlayer([ sequence ], currentId);
      await this.startSection$(sequence.id, true);
    }
  };

  getCurrentSection = () => this.storyBoard.run[this.curSequenceSection];

  getDebugInfo = () => this.screenCommunicationService.getDebugInfo();

  getDebugActions = () => this.screenCommunicationService.getDebugActions();

  subscribe() {
    subscribe('history', this);
    subscribe('lastScreenId', this);
  }

  ngOnDestroy(): void {}
}
