type Cases<T extends string | number, R> = Record<T, R>;

interface SwitchCases<T extends string | number, R> {
  cases: Cases<T, R>;
  otherwise?(c: T): R;
}

export function switchCase<T extends string | number, R>(
  cases: SwitchCases<T, R>
) {
  return {
    on: (value: T) =>
      cases.cases[value] || (cases.otherwise && cases.otherwise(value)),
  };
}

export function addCase<T extends string | number, R>(
  cases: Cases<T, R>,
  newCase: { if: T; then: R }
) {
  cases[newCase.if] = newCase.then;
}
