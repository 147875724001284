<div id="video-bar"></div>
<div class="footer">
  <div id="footer-block">
    <div class="header">{{ title }}</div>
    <div class="text" [innerHTML]="text"></div>
    <div class="infos small">
      <div class="debugactions" *ngIf="showDebug">
        <button mat-icon-button (click)="onRefreshActionsClicked()">
          <mat-icon class="material-icons">refresh</mat-icon>
        </button>
        <form>
          <mat-form-field appearance="standard">
            <mat-label>Actions</mat-label>
            <mat-select
              panelClass="footer-select-panel"
              [(value)]="selectedAction"
            >
              <mat-option [value]=""> </mat-option>
              <mat-option
                *ngFor="let action of debugActions | keyvalue"
                [value]="action.key"
              >
                {{ action.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>Parameter</mat-label>
            <input
              matInput
              [(ngModel)]="parameters"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </form>
        <button mat-icon-button (click)="onExecuteActionClicked()">
          <mat-icon class="material-icons">play_circle_filled</mat-icon>
        </button>
      </div>
      <div *ngIf="false">
        <a href="javascript:void(0)" (click)="getInfoText('termsOfUse')"
          >Nutzungsbedingungen</a
        >
      </div>
      <div>
        <a href="javascript:void(0)" (click)="getInfoText('systemRequirements')"
          >Systemvoraussetzungen</a
        >
      </div>
      <div>
        <a
          href="javascript:void(0)"
          (click)="getInfoText('dataProtectionRegulation')"
          >Datenschutz</a
        >
      </div>
      <div>
        <a href="javascript:void(0)" (click)="getInfoText('legalNotice')"
          >Impressum</a
        >
      </div>
    </div>
  </div>
</div>
