<ng-template #loading>Loading...</ng-template>

<div>
  <span
    *ngIf="data && data.id; else loading"
    [innerHTML]="
      (projectInfoTexts$ | async)[data.id] || 'no data' | sanitize: 'html'
    "
  ></span>
</div>

<!-- <ng-template #systemRequirements>
  <h1>Systemvoraussetzungen</h1>
  <div>
    Bitte stellen Sie sicher, dass Sie JavaScript aktiviert haben, um unser
    interaktives Video in vollem Umfang erleben zu k&ouml;nnen.
  </div>
  <div>&nbsp;</div>
  <div>
    Unser interaktives Video l&auml;sst sich auf Ger&auml;ten (Devices) mit
    HTML5-MP4-Video- Playback-Unterst&uuml;tzung abspielen.
  </div>
  <div>&nbsp;</div>
  <div>Kompatible Betriebssysteme und Browser:</div>
  <ul>
    <li><strong>Windows</strong>:&nbsp;Firefox, Chrome, Edge</li>
    <li><strong>OSX</strong>:&nbsp;Safari, Firefox, Chrome</li>
    <li><strong>iOS</strong>:&nbsp;Mobile Safari: iPad</li>
    <li><strong>Android</strong>:&nbsp;ab Android 4.4 Browsers</li>
  </ul>
  <div>
    <span style="text-decoration: underline;">Safari f&uuml;r Windows:</span
    >&nbsp;Dieser Browser wird nicht mehr von Apple weiterentwickelt und
    unterst&uuml;tzt. Unser System ist daher nicht auf den Gebrauch mit diesem
    Browser ausgerichtet.
  </div>
  <div>&nbsp;</div>
  <h2>Tipps zum Video-Streaming</h2>
  <div>
    Zum einwandfreien Abspielen unseres interaktiven Videos ben&ouml;tigen Sie
    idealerweise&nbsp;eine Breitband-Internet-Verbindung. Sollte Ihre
    Breitband-Verbindung nicht schnell&nbsp;genug sein, kann unser interaktives
    Video ruckeln. Wir empfehlen eine Downstream-Brandbreite von mindestens
    1.500kbit/s.
  </div>
  <div>&nbsp;</div>
  <div>
    Sie k&ouml;nnen Ihre Downstream-Bandbreite auf diversen Webseiten
    pr&uuml;fen. Suchen Sie&nbsp;daf&uuml;r im Internet nach
    &ldquo;Bandbreitentest&rdquo; oder &ldquo;Speedtest&rdquo;
  </div>
  <div>&nbsp;</div>
  <div>
    Hier einige Tipps, wenn unser Video nicht einwandfrei auf Ihrem Ger&auml;t
    laufen sollte:
  </div>
  <ul>
    <li>
      Ihre Video-Download-Geschwindigkeit ist langsam oder instabil. Pausieren
      Siedas Video bis das Video vollst&auml;ndig geladen ist und starten Sie
      das Video erneut.
    </li>
    <li>
      Sollten Sie das Video &uuml;ber eine W-LAN-Verbindung anschauen und das
      Videoruckelt, versuchen Sie das Video &uuml;ber eine LAN-Verbindung
      anzusehen oder positionieren Sie Ihr Ger&auml;t n&auml;her am
      W-LAN-Router, um die W-LAN-Verbindungzu verbessern.
    </li>
    <li>
      Sollten Sie zeitgleich andere Anwendungen nutzen, die eine hohe Bandbreite
      ben&ouml;tigen, (z.B. Outlook, andere Downloads, Internet-Telefondienste
      wie z.B. Skype), pr&uuml;fen Sie bitte, ob Sie diese pausieren
      k&ouml;nnen, um gen&uuml;gend Bandbreitef&uuml;r unser interaktives Video
      zur Verf&uuml;gung zu haben.&nbsp;
    </li>
    <li>
      <div>
        Sollten Sie Ihre Internetverbindung mit anderen Nutzern teilen, z.B. in
        einem (Firmen-) Netzwerk, pr&uuml;fen Sie, ob diese andere
        bandbreiten-intensive&nbsp;Programme nutzen, w&auml;hrend Sie unser
        interaktives Video anschauen.
      </div>
    </li>
    <li>
      <div>
        Wenn unser interaktives Video noch immer ruckelt, gibt es ggf. Probleme
        mit&nbsp;Ihrer CPU-Leistung. Versuchen Sie CPU-intensive Programme zu
        schlie&szlig;en oder Ihr Ger&auml;t neu zu starten.
      </div>
    </li>
  </ul>
  <h3>Unser interaktives Video l&auml;sst sich nicht abspielen</h3>
  <div>
    Bitte stellen Sie sicher, dass Sie JavaScript mit
    HTML5-MP4-Video-Playback-Unterst&uuml;tzung&nbsp;aktiviert haben.
    Pr&uuml;fen Sie auch, ob Sie Videos auf Webseiten wie&nbsp;<a
      href="http://youtube.com/"
      >YouTube</a
    >&nbsp;abspielen&nbsp;k&ouml;nnen. Wenn Sie Videos auf anderen Webseiten
    abspielen k&ouml;nnen und trotzdem&nbsp;Schwierigkeiten haben, unser
    interaktives Video abzuspielen, kann dies durch individuelle Einstellungen
    Ihres Anti-Virus-Programms oder installierter Plug-Ins verursacht werden.
  </div>
  <div>
    Bitte senden Sie uns f&uuml;r weitere Hilfe eine E-Mail an&nbsp;<a
      href="mailto:support@d3f.de"
      >support@d3f.de</a
    >.
  </div>
</ng-template> -->
