<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<div mat-dialog-content>{{ data.content }}</div>
<div mat-dialog-actions>
  <button (click)="onButtonClick(false)" mat-button cdkFocusInitial>
    {{ data.noLabel || 'Nein' }}
  </button>
  <button (click)="onButtonClick(true)" mat-button cdkFocusInitial>
    {{ data.yesLabel || 'Ja' }}
  </button>
</div>
