import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from 'src/app/shared/services/messaging.service';


@Component({
  selector: 'dwerk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  logoUrl = '';
  @Input()
  title = '';
    // 'VKB - Krankenzusatzversicherung nach Maß';
  @Input()
  underline = '';
    // 'Bedarfsgerecht und individuell';

  constructor(private messagingService: MessagingService) {
    this.registerMessages();
  }

  private registerMessages(): void {}

  ngOnInit() {}
}
