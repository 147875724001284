import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dwerk-system-requirements',
  templateUrl: './system-requirements.component.html',
  styleUrls: ['./system-requirements.component.scss'],
})
export class SystemRequirementsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
