import { ErrorType } from './error-types';

type EnumDictionary<T extends string | symbol | number, U> = { [K in T]: U };

export const ErrorMessages: EnumDictionary<ErrorType, string> = {
  InvalidDate: 'Die gegebene Seite ist zu diesem Zeitpunkt nicht gültig.',
  VideoRunError: 'Während der Beratung hat etwas nicht geklappt..',
  InvalidStatus: 'Das Projekt ist noch nicht aktiv.',
  InvalidUrl: 'Die eingegebene URL ist nicht zulässig.',
  InvalidAgent: 'Der Berater ist diesem Projekt nicht zugeordnet.',
  InvalidProject: 'Das angegebene Projekt existiert nicht',
  NotFound: 'Ich habe etwas wichtiges nicht gefunden....',
  Unexpected: 'Mit diesem Fehler habe ich nicht gerechnet...',
  PrepareScreenError:
    'Beim Vorbereiten des nächsten Absvhnitt ist ein Fehler aufgetreten',
  // tslint:disable-next-line: quotemark
  Default: 'Dieser Fehler kam unerwartet.',
};

export const errorTitle = 'Oops... ein Fehler ist aufgetreten.';
