<h1>Impressum&nbsp;</h1>

<p>
  <strong>dWERK GmbH &amp; Co. KG</strong>&nbsp;<br />
  Darmst&auml;dter Str. 170       &nbsp;<br />
  64625 Bensheim&nbsp;<br />
  &nbsp;
</p>

<p>
  <a href="tel:+4972428960704" target="_blank"
    >Telefon: +49 7242 - 89 60 704&nbsp;</a
  ><br />
  <a href="mailto:info@dwerk.de" target="_blank"
    >E-Mail: info@dwerk.de&nbsp;<br />
    &zwj;</a
  >&nbsp;
</p>

<p>
  Sitz der Gesellschaft: Bensheim&nbsp;&nbsp;<br />
  Handelsregister HRA 86548&nbsp;<br />
  Amtsgericht Darmstadt&nbsp;<br />
  &nbsp;<br />
  Pers&ouml;nlich&nbsp;haftende Gesellschafterin der dWERK GmbH &amp; Co.
  KG:&nbsp;<br />
  &nbsp;<br />
  AUTOBAV&nbsp;Verwaltungs&nbsp;GmbH&nbsp;<br />
  Sitz der Gesellschaft:&nbsp;Moisburg&nbsp;<br />
  Handelsregister HRB 204897, Amtsgericht Tostedt&nbsp;<br />
  Gesch&auml;ftsf&uuml;hrer: Torsten Burkart&nbsp;<br />
  &nbsp;
</p>

<p>
  Steuernummer: 47/615/01149&nbsp;<br />
  Umsatzsteuer-Identifikationsnummer: DE299552629&nbsp;
</p>

<h1>Rechtliche Hinweise&nbsp;</h1>

<p>
  <strong>Streitschlichtung&nbsp;</strong><br />
  Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
  einer Verbraucherschlichtungsstelle teilzunehmen.&nbsp;<br />
  &nbsp;<br />
  <strong>Haftung f&uuml;r Inhalte</strong>&nbsp;<br />
  Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r
  eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
  Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
  verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu
  &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine
  rechtswidrige T&auml;tigkeit hinweisen.&nbsp;<br />
  &nbsp;<br />
  Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
  nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine
  diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
  einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
  entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
  entfernen.&nbsp;<br />
  &nbsp;<br />
  <strong>Haftung f&uuml;r Links&nbsp;</strong><br />
  Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren
  Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese
  fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte
  der verlinkten Seiten ist stets der jeweilige&nbsp;Anbieter oder Betreiber der
  Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
  Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft.
  Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
  erkennbar.&nbsp;<br />
  &nbsp;<br />
  Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
  konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
  Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
  entfernen.&nbsp;<br />
  &nbsp;<br />
  <strong>Urheberrecht&nbsp;</strong><br />
  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
  unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung,
  Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der
  Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des
  jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
  f&uuml;r den privaten, nicht kommerziellen Gebrauch
  gestattet.&nbsp;Soweit&nbsp;die Inhalte auf dieser Seite nicht vom Betreiber
  erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
  werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
  eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
  entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
  derartige Inhalte umgehend entfernen.&nbsp;
</p>
