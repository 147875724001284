import { DataService } from '@app/modules/storyboard/services/actions/data.service';
import { environment } from 'src/environments/environment';

export function onInit(dataService: DataService) {
  return async () => {
    checkForIE();

    await loadSettings$(dataService);
  };
}

async function loadSettings$(dataService: DataService) {
  const settings = await dataService.loadSettings$();

  if (settings) {
    environment.baseUri = settings.baseUri || environment.baseUri;
    environment.cdnBaseUri = settings.cdnBaseUri || environment.cdnBaseUri;
    environment.projectCdnBaseUri =
      settings.projectCdnBaseUri || environment.projectCdnBaseUri;
  }
}

function checkForIE() {
  const isIE = !!(document as any).documentMode;
  environment.isIE = isIE;

  if (isIE) {
    require('es6-symbol/implement');
  }
}
