import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BaseComponent } from '@app/shared/components/base-component';
import { Message } from '@app/shared/models/messages';
import { DialogService } from '@app/shared/services/dialog.service';
import { Agent } from '@app/shared/state/models/data/agent.model';
import { Observable } from 'rxjs';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { environment } from 'src/environments/environment';
import { SessionQuery } from './../../shared/state/session.query';
import { Greeting } from './Models/greeting-item';

const additionalDuration = 2;

@Component({
  selector: 'dwerk-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
})
export class SplashscreenComponent extends BaseComponent
  implements OnInit, OnDestroy {
  @Input()
  remove$?: Observable<boolean>;
  @Input()
  isDone: (isDone: boolean) => void;
  shouldHideText = true;
  text = '';
  shouldHide = false;
  isProd = environment.production;
  agent$: Observable<Agent>;

  private presentation: Greeting = [
    { text: 'Hallo!', duration: 1 },
    { text: 'Herzlich willkommen.', duration: 1.5 },
    { text: 'Wir bereiten gerade alles Wichtige für Sie vor.', duration: 1 },
    { text: 'Es geht gleich los!', duration: 1 },
  ];
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private messagingService: MessagingService,
    private dialogService: DialogService,
    private query: SessionQuery
  ) {
    super();

    this.isDone = (hide: boolean) => (this.shouldHide = hide);
    this.agent$ = query.agent$;
  }

  ngOnInit() {
    this.messagingService.on<boolean>(Message.VideoInitialized, () => {
      this.showPresentation(this.presentation);
    });
    this.messagingService.on<boolean>(
      Message.HasErrorMessage,
      (hasError) => (this.shouldHide = hasError)
    );
    if (!this.isProd) {
      this.subscribe(this.agent$, (agent) => {
        this.presentation.push({
          text: `Ihr Berater ist ${agent.name}.`,
          duration: 1,
        });
      });
    }
  }

  showPresentation(presentation: Greeting) {
    this.showItem(0, presentation);
  }
  async showItem(currIndex: number, presentation: Greeting) {
    if (this.dialogService.result$) {
      await this.dialogService.result$;
    }

    const { text, duration } = presentation[currIndex];
    this.text = text;
    setTimeout(() => {
      this.shouldHideText = false;
      this.changeDetectorRef.markForCheck();
    }, 10);

    setTimeout(
      () => this.hideItem(currIndex, presentation),
      1000 * (duration + additionalDuration)
    );

    this.changeDetectorRef.markForCheck();
  }

  async hideItem(currIndex: number, presentation: Greeting) {
    currIndex++;
    if (!this.shouldHide && currIndex < presentation.length) {
      this.shouldHideText = true;
      setTimeout(
        () => this.showItem(currIndex, presentation),
        1000 * additionalDuration
      );
    } else {
      this.shouldHideText = true;
      this.shouldHide = true;
      this.isDone(true);
    }

    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
