export function getSubdomain() {
  return getHostPartsAndSubdomain().subDomain;
}

export function getOriginWithoutWWW(origin: string) {
  let originWithoutWWW = origin;
  const hostPartsAndSubdomain = getHostPartsAndSubdomain();

  if (hostPartsAndSubdomain.hostParts[0] === 'www') {
    originWithoutWWW = origin.replace('://www.', '://');
  }

  return originWithoutWWW;
}

function getHostPartsAndSubdomain() {
  if (location.host.includes('.azurewebsites.')) {
    return { hostParts: [], subDomain: undefined };
  }

  const host = location.host;
  const hostParts = host.split('.');

  const subDomain =
    hostParts.length >= 3
      ? hostParts[0] !== 'www'
        ? hostParts[0]
        : hostParts.length > 3 
          ? hostParts[1] 
          : undefined
      : undefined;
  return { hostParts, subDomain };
}
