import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/components/base-component';
import {
  Message,
  ProgressBarMessagePayload,
  VideoCommand,
  VideoPlayStateChangedPayload,
} from '@app/shared/models/messages';
import { MessagingService } from '@app/shared/services/messaging.service';
import { Project } from '@app/shared/state/models/data/project.model';
import { StoryBoard } from '@app/shared/state/models/screens/storyBoard.model';
import { SessionQuery } from '@app/shared/state/session.query';
import { Observable } from 'rxjs';
import { PhaseModel } from './models/phase.model';

@Component({
  selector: 'dwerk-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent extends BaseComponent implements OnInit {
  phases: PhaseModel[] = [];
  itemWidth = '0';
  progressbarWidth = '0';

  private project$: Observable<Project>;
  isInPlayMode = true;
  canPlay = false;
  isFullscreen = false;
  isSoundOn = true;

  constructor(
    private query: SessionQuery,
    private messagingService: MessagingService
  ) {
    super();
    this.project$ = this.query.project$;
  }

  ngOnInit() {
    this.subscribe(this.project$, project =>
      this.buildProgressBar(project.storyboard)
    );

    this.addSubscription(
      this.messagingService.on<ProgressBarMessagePayload>(
        Message.ProgressBarProceedMessage,
        payload => this.updatePhases(payload)
      )[1]
    );
    this.addSubscription(
      this.messagingService.on<VideoPlayStateChangedPayload | undefined>(
        Message.VideoPlayStateChanged,
        playState => {
          if (playState) {
            this.updatePlayStates(playState);
          } else {
            this.canPlay = true;
          }
        }
      )[1]
    );
  }
  updatePlayStates({
    hasStarted,
    isPlaying,
    hasReachedEnd,
  }: VideoPlayStateChangedPayload): void {
    this.isInPlayMode = !isPlaying;
    this.canPlay = !hasReachedEnd && !hasStarted;
  }

  private buildProgressBar(storyBoard: StoryBoard) {
    if (storyBoard) {
      this.initPhases(storyBoard.phases);
      this.calculateItemWidth();
    }
  }

  private calculateItemWidth() {
    const itemWidth = 100 / this.phases.length;

    this.itemWidth = itemWidth + '%';
    // this.progressbarWidth = `calc(${100 + itemWidth}% - 200px)`;
    this.progressbarWidth = `calc(100% - 150px)`;
  }

  private initPhases(phases: string[]) {
    this.phases = phases.map(phase => {
      return {
        title: phase,
        isActive: false,
        isFinished: false,
      };
    });
    this.phases.push({
      title: '',
      isActive: false,
      isFinished: false,
    });
    this.setPhaseActiveStatus(this.phases[0]);
  }

  onPlayClick() {
    const command = VideoCommand.play;
    this.executeVideoCommand(command);
  }
  onPauseClick() {
    const command = VideoCommand.pause;
    this.executeVideoCommand(command);
  }
  onToggleSound() {
    this.isSoundOn = !this.isSoundOn;

    const command = this.isSoundOn
      ? VideoCommand.soundOn
      : VideoCommand.soundOff;
    this.executeVideoCommand(command);
  }
  onToggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;

    const command = this.isFullscreen
      ? VideoCommand.fullscreenOn
      : VideoCommand.fullscreenOff;
    this.executeVideoCommand(command);
  }
  private executeVideoCommand(command: VideoCommand) {
    this.messagingService.broadcast(Message.EcxecuteVideoCommmand, command);
  }
  /*
  private setFinishedEndPhase() {
    const lastPhase = this.phases[this.phases.length - 1];
    this.setPhaseToFinished(lastPhase);
}
*/

  private updatePhases({ nextPhase, shouldFinish }: ProgressBarMessagePayload) {
    const currentPhaseIndex =
      nextPhase && !shouldFinish
        ? this.phases.findIndex(elem => elem.title === nextPhase)
        : this.phases.length;

    if (currentPhaseIndex <= this.phases.length) {
      this.phases.forEach((phaseModel, ii) => {
        this.setPhaseActiveStatus(phaseModel, ii <= currentPhaseIndex);
        this.setPhaseFinishedStatus(phaseModel, ii < currentPhaseIndex);
      });
    }
  }

  private setPhaseActiveStatus = (
    phase: PhaseModel,
    isActive: boolean = true
  ) => (phase.isActive = isActive);
  private setPhaseFinishedStatus = (
    phase: PhaseModel,
    isFinisshed: boolean = true
  ) => (phase.isFinished = isFinisshed);
}
