import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertData } from '../components/alert-dialog/alert-dialog.component';
import { ConfirmationData } from '../components/confirm-dialog/confirm-dialog.component';
import { ReenterKeyData, ReenterKeyDialogComponent } from '../components/reenter-key-dialog/reenter-key-dialog.component';
import { AlertDialogComponent } from './../components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './../components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  result$?: Promise<any>;
  constructor(public dialog: MatDialog) {}

  openAlert$(data: AlertData): Promise<void> {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: data.width || '400px',
      data,
    });

    this.result$ = dialogRef.afterClosed().toPromise();
    return this.result$;
  }
  openConfirmation$(data: ConfirmationData): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: data.width || '400px',
      data,
    });

    this.result$ = dialogRef.afterClosed().toPromise();
    return this.result$;
  }
  openKeyReentry$(data: ReenterKeyData): Promise<string> {
    const dialogRef = this.dialog.open(ReenterKeyDialogComponent, {
      width: data.width || '400px',
      data, 
      disableClose: true
    });

    this.result$ = dialogRef.afterClosed().toPromise();
    return this.result$;
  }
}
