import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { urlEquals } from './../../core/functions/string-functions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const tokenPayload = this.authService.tokenPayload;
    if (!this.authService.isAuthorized || !tokenPayload) {
      this.authService.clearToken();
      this.router.navigate(['login'], {
        queryParams: route.queryParams,
        skipLocationChange: true,
      });
      // https://stackoverflow.com/questions/45035660/angular-routing-canactivate-authguard-transmit-query-params
      // verdict: queryParamsHandling not working inside a guard
      // this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
      return false;
    } else if (!tokenPayload.isValid()) {
      this.authService.clearToken();
      this.router.navigate(['login'], {
        queryParams: route.queryParams,
        skipLocationChange: true,
      });
      return false;
    } else if (
      !urlEquals(tokenPayload.url, withoutWww(window.origin)) ||
      tokenPayload.projectIdentifier !== route.queryParams.projectId
    ) {
      this.authService.clearToken();
      this.router.navigate(['login'], {
        queryParams: route.queryParams,
        skipLocationChange: true,
      });
      return false;
    }
    return true;
  }
}

function withoutWww(url: string): string {
  return url.replace('://www.', '://');
}
