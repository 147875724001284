import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlJoin } from '@app/core/functions/string-functions';
import { ErrorType } from '@app/shared/constants/error-types';
import {
  handleError,
  raiseError$,
} from '@app/shared/functions/error-functions';
import { SessionService } from '@app/shared/state/session.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EmployeeDataRequest } from '../models/EmployeeDataRequest';
import { CreateDocumentService } from './actions/create-document.service';
import { DataService } from './actions/data.service';
import { StoryboardService } from './storyboard.service';

const apiUri = environment.baseUri;
type AsyncAction = (arg: any) => Promise<unknown>;
type NonAsyncAction = (arg: any) => unknown;

@Injectable({
  providedIn: 'root',
})
export class ServerActionService {
  private portalActions: Record<string, AsyncAction> = {};
  constructor(
    private http: HttpClient,
    createDocService: CreateDocumentService,
    dataService: DataService,
    sessionService: SessionService
  ) {
    this.registerActions(createDocService, dataService, sessionService);//, storyboardService);
  }

  async execute$(
    actionName: string,
    data: unknown,
    onError?: (e: Error) => void,
    isDataPrepared = false
  ): Promise<unknown> {
    if (actionName.toLowerCase().startsWith('portal.')) {
      return this.portalActions[actionName](data).catch(
        onError ||
          (error =>
            raiseError$({
              errorType: ErrorType.Unexpected,
              message: error && error.message,
            }))
      );
    }

    const backendUrl = urlJoin(apiUri, 'Action', actionName);

    let response$ = this.http.post<unknown>(
      backendUrl,
      prepareForServer(data, isDataPrepared)
    );
    response$ = this.addErrorHandler(response$, onError);

    return handleError(response$).toPromise();
  }

  private addErrorHandler(
    response$: Observable<unknown>,
    onError: ((e: Error) => void) | undefined
  ) {
    if (onError) {
      return response$.pipe(
        catchError((error, _) => {
          onError(error);
          return of(undefined);
        })
      );
    }

    return response$;
  }

  registerAction(name: string, action$: AsyncAction): void {
    this.portalActions[name] = action$;
  }
  registerNonAsyncAction(name: string, action: NonAsyncAction) {
    this.portalActions[name] = x => Promise.resolve(action(x));
  }
  private registerActions(
    createDocService: CreateDocumentService,
    dataService: DataService,
    sessionService: SessionService
  ) {
    this.registerAction(
      'portal.createPdfHandle',
      ({ template, data }: { template: string; data: unknown }) =>
        createDocService.createPdfHandle$(template, data)
    );
    this.registerAction(
      'portal.createDocument',
      ({ template, data }: { template: string; data: unknown }) =>
        createDocService.createDocument$(template, data)
    );
    this.registerAction(
      'portal.downloadDocument',
      ({
        template,
        data,
        name,
      }: {
        template: string;
        data: unknown;
        name: string;
      }) => createDocService.downloadDocument$(template, data, name)
    );
    this.registerAction('portal.getEmployeeData', (request: EmployeeDataRequest | string) =>
      dataService.loadEmployeeData$(request)
    );
    this.registerAction(
      'portal.callServer',
      async ({
        target,
        method,
        data,
      }: {
        target: string;
        method: string;
        data: unknown;
      }) => {
        try {
          const rawResult = (await this.execute$(`server.${target}.${method}`, {
            json: JSON.stringify(data),
          })) as {
            result: string;
          };
          return {
            isSuccess: true,
            raw: rawResult,
            result: JSON.parse(rawResult.result),
          };
        } catch (error) {
          return { isSuccess: false, error };
        }
      }
    );
    this.registerAction('portal.tryResumeConsultancy', async (userId: string) => {
      return sessionService.tryResumeConsultancy$(userId);
    });
  }
}

function prepareForServer(data: unknown, isDataPrepared: boolean) {
  return isDataPrepared
    ? data
    : {
        json: JSON.stringify(data),
      };
}

function toErrorHandledAction(
  action: AsyncAction,
  onError?: (e: Error) => void
) {}
