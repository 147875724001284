import { Injectable } from '@angular/core';
import { isNil } from '@app/core/functions/type-guards';
import { ErrorType } from '@app/shared/constants/error-types';
import { ConsultancyLoadedPayLoad, Message } from '@app/shared/models/messages';
import { MessagingService } from '@app/shared/services/messaging.service';
import { ScriptService } from '@app/shared/services/script.service';
import { ConsultancyData } from '@app/shared/state/models/data/consultancy.model';
import { ProjectInfo } from '@app/shared/state/models/data/projectInfo.model';
import { Sequence } from '@app/shared/state/models/screens/story.model';
import { StoryBoardInternal } from '@app/shared/state/models/screens/storyBoard.model';
import { SessionQuery } from '@app/shared/state/session.query';
import { SessionService } from '@app/shared/state/session.service';
import { environment } from 'src/environments/environment';
import {
  Control,
  knockout,
  StoryboardControlExternal,
} from '../models/control.model';
import { ExternalScreenViewModel } from '../models/external-function';
import { SequenceControlObject } from '../models/sequence-control-object.model';
import { VideoControl } from '../models/video-control';
import { ServerActionService } from './server-action.service';

declare const $: any;

@Injectable({
  providedIn: 'root',
})
export class ScreenCommunicationService {
  ko = knockout;
  private control: Control = {} as Control;
  private consultancyData?: ConsultancyData;
  private videoTimeObservable$!: ko.Observable<[number, string]>;
  private videoControl!: VideoControl;
  private projectInfo!: ProjectInfo;
  private storyBoard!: StoryBoardInternal;
  private currentSequence!: Sequence;
  private externalVM?: ExternalScreenViewModel;
  screenFunction?: (sco: SequenceControlObject) => ExternalScreenViewModel;

  get hasStarted() {
    return !!this.externalVM;
  }

  constructor(
    private scriptService: ScriptService,
    private sessionService: SessionService,
    private actionService: ServerActionService,
    private messenger: MessagingService,
    private query: SessionQuery
  ) {
    //console.log("SessionQuery:");
    //console.log(query);
    this.query.project$.subscribe((project) => {
      this.projectInfo = project && project.projectInfo;
      const storyBoard = project && project.storyboard;

      this.storyBoard = {
        ...storyBoard,
        ...project.paths,
      };

      this.consultancyData = {
        type: this.projectInfo.type,
        urlParameters: query.getValue().urlParameters,
      }; //TODO QueryParams einfügen
    });
    // this.query.consultancy$.subscribe(
    //   consultancy => (this.consultancyData = consultancy)
    // );

    this.messenger.on<ConsultancyLoadedPayLoad>(
      Message.ConsultancyLoaded,
      (payload) => {
        this.consultancyData = payload.consultancyData;
        this.externalVM = undefined;
      }
    );
  }

  async init$(
    storyboardControl: StoryboardControlExternal,
    videoControl: VideoControl
  ) {
    await this.loadStoryScript$();
    this.setInitialValues(storyboardControl, videoControl);
  }

  updateTimeObservable(newTime: number, sequenceId: string) {
    if (sequenceId === (this.currentSequence && this.currentSequence.id)) {
      this.videoTimeObservable$([newTime, sequenceId]);
    }
  }

  getConsultancyData() {
    this.consultancyData =
      (!isNil(this.externalVM) &&
        this.externalVM.getConsultancyData &&
        this.externalVM.getConsultancyData()) ||
      this.consultancyData;
    return this.consultancyData;
  }

  private setInitialValues(
    storyboardControl: StoryboardControlExternal,
    videoControl: VideoControl
  ) {
    this.videoTimeObservable$ = this.ko.observable([0, '']);
    this.videoControl = this.videoControl || videoControl;
    this.control = {
      storyboardControl,
      cdnBaseUrl: environment.projectCdnBaseUri,
      ko: this.ko,
      jQuery: $,
    };
  }

  private async loadStoryScript$() {
    await this.scriptService.loadLibsFromCdn$(this.storyBoard.libs);

    const javascriptFileName = this.storyBoard.javascript;
    const cssFileName = this.storyBoard.css;

    // console.log("Story Assets", this.storyBoard.storyScript, this.storyBoard.storyStyles);
    if (this.storyBoard.storyScript) {
      this.scriptService.loadStoryScript(this.storyBoard.storyScript);
    }
    if (this.storyBoard.storyStyles) {
      this.sessionService.loadStoryStyles(this.storyBoard.storyStyles);
    }

    // if (this.isJust(javascriptFileName, 'No Javascript file set')) {
    if (!isNil(javascriptFileName) && javascriptFileName) {
      await this.scriptService.loadStoryBoardScript$(javascriptFileName);
    }
    if (!isNil(cssFileName) && cssFileName) {
      await this.sessionService.loadCSSFromCDN$(cssFileName);
    }
  }

  isJust(value: unknown, messageIfNil?: string) {
    if (isNil(value)) {
      this.control.storyboardControl.error(ErrorType.NotFound, messageIfNil);
      return false;
    }
    return true;
  }

  async setExternalView$(sequence: Sequence) {
    if (sequence.script) {
      await this.videoControl.setScreenScript$(sequence.script);
    }

    this.externalVM = this.callStoryBoardFunction(sequence.functionAtStart);
    this.externalVM.timer = knockout.pureComputed(
      () => this.videoTimeObservable$() && this.videoTimeObservable$()[0]
    );
    this.currentSequence = sequence;
    this.setView(sequence, this.externalVM);
  }

  private callStoryBoardFunction(functionName: string) {
    const inputParams = this.newSequenceControl();

    return this.scriptService.callStoryBoardFunction(functionName, inputParams);
  }

  private setView(sequence: Sequence, externalVM: ExternalScreenViewModel) {
    this.setViewHtml(sequence.id, sequence.screen, sequence.script, externalVM);
    this.videoControl.setStoryVideo(sequence.id);
  }

  private setViewHtml(
    id: string,
    html: string,
    script: string,
    externalVM?: ExternalScreenViewModel
  ) {
    this.videoControl.setScreenHtml({
      id,
      template: html,
      data: externalVM,
    });
  }

  private newSequenceControl(): SequenceControlObject {
    this.videoTimeObservable$ = this.ko.observable([0, '']);
    const consultancy = this.getConsultancyData();
    const projectInfo = this.projectInfo;

    return {
      videoTimeObservable: this.videoTimeObservable$,
      consultancy,
      projectInfo,
      actionAsync: (action, arg, onError) =>
        this.methodHandler$(action, arg, onError),
      control: { ...this.control },
    };
  }
  async methodHandler$(
    actionName: string,
    arg: unknown,
    onError?: (e: Error) => void
  ) {
    return this.actionService.execute$(actionName, arg, onError);
  }

  getDebugInfo() {
    return {
      projectInfo: this.projectInfo,
      screen: this.currentSequence || {},
      consultancyData: this.consultancyData || {},
      vm: {
        model:
          this.ko?.vm?.toJsModel(this.externalVM?.model || {}) ||
          this.externalVM?.model,
        screenData:
          this.ko?.vm?.toJsModel(this.externalVM?.screenData || {}) ||
          this.externalVM?.screenData,
      },
      history: this.sessionService.historyView(),
    };
  }

  getDebugActions() {
    return this.externalVM?.actions;
  }
}
