import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store, StoreConfig } from '@datorama/akita';
import produce from 'immer';
import { ThemeInfoTexts } from '../models/theme.model';
import { Agent, createAgent } from './models/data/agent.model';
import { ConsultancyData } from './models/data/consultancy.model';
import { createProject, Project } from './models/data/project.model';

export interface SessionState {
  project: Project;
  agent: Agent;
  consultancyData: ConsultancyData;
  themeInfoTexts: ThemeInfoTexts;
  screenHistory: string[];
  userId?: string;
  urlParameters: Params;
}

export function createInitialState(): SessionState {
  return {
    project: createProject(),
    agent: createAgent(),
    consultancyData: { type: 'BAV' },
    themeInfoTexts: {
      header: '',
      underline: '',
      footerHeader: '',
      footerUnderline: '',
    },
    screenHistory: [],
    urlParameters: {},
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}

export function updateStore(
  store: SessionStore,
  updater: (draftState: SessionState) => void
) {
  store.update((state) => {
    const newState = produce(state, updater);
    return newState;
  });
}
