import { Component, OnDestroy } from '@angular/core';
import { raiseError$ } from '@app/shared/functions/error-functions';
import { Observable, Subscription } from 'rxjs';
import { ErrorType } from '../constants/error-types';

@Component({ selector: 'dwerk-base', template: '' })
export class BaseComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor() {}

  protected addSubscription(subscription?: Subscription) {
    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  protected subscribe<T>(
    observable: Observable<T>,
    callback: (params: T) => void
  ) {
    const subscription = observable.subscribe(callback);
    this.subscriptions.push(subscription);
  }
  protected unsubscribe() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe);
  }

  raiseError(errorType: ErrorType, message?: string) {
    raiseError$({ errorType, message });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
