import { ErrorType } from '@app/shared/constants/error-types';
import { Agent } from '@app/shared/state/models/data/agent.model';
import {
  ConsultancyData,
  ConsultancyStatus,
} from '@app/shared/state/models/data/consultancy.model';
import { ProjectInfo } from '@app/shared/state/models/data/projectInfo.model';
import * as koStaticNs from 'knockout';
import * as koVm from 'knockout-viewmodel';

type KoStatic = typeof koStaticNs;
export interface Ko extends KoStatic {
  vm: {
    toJsModel(koViewModel: any): any;
    fromJsModel(jsModel: any, options?: any): any;
  };
}

const ko = expandKnockout(koStaticNs);
// ToDo
(window as any).ko = ko;
const vm = {
  toJsModel: (koViewModel: any) => ko.viewmodel.toModel(koViewModel),
  fromJsModel: (jsModel: any, options?: any) =>
    ko.viewmodel.fromModel(jsModel, options),
};

export const knockout: Ko = {
  ...ko,
  vm,
};

declare var toKoPunches: (ko: KoStatic) => void;
declare var toKoVm: (ko: KoStatic) => void;
// tslint:disable-next-line: no-shadowed-variable
function expandKnockout(ko: KoStatic): KoStatic & { viewmodel: typeof koVm } {
  toKoVm(ko);
  toKoPunches(ko);

  return ko as any;
}

export interface StoryboardControlExternal {
  pause(): Promise<void>;
  goTo(
    sequenceIdentifier: string,
    consultancyData: ConsultancyData
  ): Promise<void>;
  goForward(consultancyData: ConsultancyData): Promise<void>;
  goBack(consultancyData: ConsultancyData): Promise<void>;
  toFullscreen(isFullscreen?: boolean): void;
  logout(): void;
  saveAsync(
    consultancyData: ConsultancyData,
    status?: ConsultancyStatus
  ): Promise<void>;
  saveAndGoForwardAsync(
    consultancyData: ConsultancyData,
    status?: ConsultancyStatus
  ): Promise<void>;
  saveAndStayAsync(
    consultancyData: ConsultancyData,
    status?: ConsultancyStatus
  ): Promise<void>;
  reload(runVideo?: boolean): void;
  getAgent(): Agent;
  getProjectInfo(): ProjectInfo;
  addCdnPath(path: string): string;
  error(errorType: ErrorType, msg?: string): Promise<void>;
  setLanguage(lang: string): void;
}

export interface Control extends Record<string, any> {
  ko: Ko;
  cdnBaseUrl: string;
  storyboardControl: StoryboardControlExternal;
}
